import React from 'react';

export default function YouTubeEmbed({ videoId, preferredWidth, preferredHeight }) {
    return (
        <iframe
            width={preferredWidth}
            height={preferredHeight}
            src={`https://www.youtube-nocookie.com/embed/${videoId}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
        >
        </iframe>
    );
}
import { Box, Grid, Stack } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DocumentLoading, RelatedDocumentsMenu, calculateDocumentPaneSize } from '../documentViewCore';
import { useWindowDimensions, useCheckForPassedInFamilyFilter } from '../../hooks';
import YouTubeEmbed from './YouTubeEmbed';
import { logDocumentOpen } from '../../fetch/insightsHelper';
import { useSelector } from 'react-redux';
import { useVideoMetadata } from '../../fetch/fetchMetadata';
import VimeoEmbed from './VimeoEmbed';

export default function VideoView() {
    const { height: viewportHeight, width: viewportWidth } = useWindowDimensions();
    const userLanguage = useSelector((state) => state.permissions.language);
    const [documentDimensions, setDocumentDimensions] = useState({ documentHeight: viewportHeight, documentWidth: viewportHeight });
    const { partNumber } = useParams();
    const { metadata, isLoading: isMetadataLoading } = useVideoMetadata(partNumber);
    const { isYouTube, isVimeo, videoName } = metadata;

    useCheckForPassedInFamilyFilter();

    useEffect(() => {
        setDocumentDimensions(calculateDocumentPaneSize(
            false,
            true,
            false,
            viewportWidth,
            viewportHeight,
            0.6));
    }, [viewportHeight, viewportWidth]);

    useMemo(() => {
        if (!!userLanguage) {
            logDocumentOpen(partNumber, userLanguage);
        }
    }, [partNumber, userLanguage]);

    if (isMetadataLoading) {
        return (
            <DocumentLoading documentHeight={documentDimensions.documentHeight} />
        );
    }

    return (
        <Box component="main" sx={{ p: 0, overflow: 'hidden', maxHeight: '100%', width: '100%' }}>
            <Grid container>
                <Grid item xs={12} xl={12}>
                    <Stack direction='row' spacing={2} justifyContent='center'>
                        {isYouTube ? (
                            <YouTubeEmbed
                                videoId={partNumber}
                                preferredHeight={documentDimensions.documentHeight}
                                preferredWidth={documentDimensions.documentWidth}
                            />
                        ) : null}
                        {isVimeo ? (
                            <VimeoEmbed
                                videoId={partNumber}
                                preferredHeight={documentDimensions.documentHeight}
                                preferredWidth={documentDimensions.documentWidth}
                            />
                        ) : null}
                        {!isYouTube && !isVimeo ? (
                            <iframe height={documentDimensions.documentHeight} width={documentDimensions.documentWidth} src={videoName} />
                        ) : null}
                    </Stack>
                </Grid>
            </Grid>
            <RelatedDocumentsMenu partNumber={partNumber} />
        </Box>
    );
}
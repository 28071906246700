export const calculateDocumentPaneSize = (
    isFlyOutOpen, isSinglePageMode, isExpandMode, viewportWidth, viewportHeight, pageRatio
) => {
    if (!isSinglePageMode && !pageRatio) {
        pageRatio = 1.3;
    }

    const startingViewportWidth = isFlyOutOpen ? viewportWidth * .70 : viewportWidth;
    const halfViewPoint = ((isSinglePageMode ? startingViewportWidth - 64 : (startingViewportWidth / 2) - 64)) * .90;

    let startingHeight = viewportHeight - 139;
    let startingWidth = startingHeight / pageRatio;

    // if we are in expand mode 
    if (isExpandMode) {
        if (startingWidth > halfViewPoint) {
            startingWidth = halfViewPoint;
            startingHeight = startingWidth * pageRatio;
        }

        while (startingWidth < halfViewPoint) {
            startingHeight = startingHeight * 1.05;
            startingWidth = startingHeight / pageRatio;
        }
        return { documentHeight: startingHeight, documentWidth: startingHeight / pageRatio };
    }
    else {
        while (startingWidth > halfViewPoint) {
            startingHeight = startingHeight * .95;
            startingWidth = startingHeight / pageRatio;
        }

        return { documentHeight: startingHeight, documentWidth: startingHeight / pageRatio };
    }
}

export const calculateDocumentPaneSizeScrollView = (
    isFlyOutOpen, isExpandMode, viewportWidth, viewportHeight, pageRatio, zoom
) => {
    if (!zoom) {
        zoom = 1;
    }

    if (!pageRatio) {
        pageRatio = 1.3;
    }

    const startingViewportWidth = isFlyOutOpen ? viewportWidth * .70 : viewportWidth;
    const halfViewPoint = (startingViewportWidth - 64) * .90;

    let startingHeight = (viewportHeight - 139) * zoom;
    let startingWidth = startingHeight / pageRatio;

    // if we are in expand mode 
    if (isExpandMode) {
        if (startingWidth > halfViewPoint) {
            startingWidth = halfViewPoint;
            startingHeight = startingWidth * pageRatio;
        }

        while (startingWidth < halfViewPoint) {
            startingHeight = startingHeight * 1.05;
            startingWidth = startingHeight / pageRatio;
        }
        return { documentHeight: startingHeight, documentWidth: startingHeight / pageRatio };
    }
    else {
        while (startingWidth > halfViewPoint) {
            startingHeight = startingHeight * .95;
            startingWidth = startingHeight / pageRatio;
        }

        return { documentHeight: startingHeight, documentWidth: startingHeight / pageRatio };
    }
}
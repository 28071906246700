import { useTheme } from "@emotion/react";
import { Grid } from "@mui/material";
import React from "react";

export default function DocumentListScrollView({ children }) {
    const theme = useTheme();
    const scrollViewerTop = theme.appBar.height + theme.subBar.height
    return (
        <Grid
            id='scroll-viewer'
            container
            style={{
                position: 'fixed',
                top: scrollViewerTop,
                left: 0,
                height: `calc(100vh - ${scrollViewerTop}px)`,
                overflowX: 'hidden',
                overflowY: 'auto',
                width: '100vw',
                padding: '1.5em 1em 1.5em 1em'
            }}
        >
            {children}
        </Grid>
    );
}
import React, { useMemo, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { Stack } from '@mui/material';
import { useDocumentPdf, useDocumentSectionPdf } from '../../../fetch/fetchPdf';
import styled from '@emotion/styled';
import { usePageImage } from '../../../fetch/fetchImage';
import { PageSpinner } from '../../documentViewCore';

const OverallContainer = styled('div')(({ theme, documentWidth, hasLeft, hasRight }) => {
    let width = `${documentWidth}px`;
    if (hasLeft && hasRight) {
        width = `calc(${documentWidth * 2}px + ${theme.spacing(1)})`
    }
    return ({
        position: 'relative',
        width: width
    });
})

const PageContainer = styled('div')(({ theme, documentWidth, documentHeight, side }) => {
    return ({
        background: theme.palette.background.paper,
        width: documentWidth,
        height: documentHeight,
        marginLeft: side == 'left' ? null : theme.spacing(1)
    });
})

export default function PdfView({ partNumber, sectionFileName, leftPageNumber, rightPageNumber, setPageNumber, documentWidth, documentHeight, offset, isOversize, hasCover }) {
    const [forceReload, setForceReload] = useState(false);

    const { pdfData, isLoading: isPdfLoading } = useDocumentSectionPdf(partNumber, !isOversize ? sectionFileName : null);
    const [docRendered, setDocRendered] = useState(false);
    const [leftDocRendered, setLeftDocRendered] = useState(false);
    const [rightDocRendered, setRightDocRendered] = useState(false);

    const forceUpdate = React.useCallback(() => {
        setForceReload(true);
        setDocRendered(false);
        setLeftDocRendered(false);
        setRightDocRendered(false);
    }, []);

    const completeUpdate = React.useCallback(() => {
        setForceReload(false);
    }, []);

    useMemo(() => {
        forceUpdate();
    }, [sectionFileName]);

    useMemo(() => {
        if (forceReload) {
            setTimeout(completeUpdate, 10);
        }
    }, [forceReload]);

    const hasLeft = leftPageNumber != null && leftPageNumber != undefined;
    const hasRight = rightPageNumber != null && rightPageNumber != undefined;

    const { image: leftImage, isLoading: leftIsLoading } = usePageImage(hasLeft ? partNumber : null, leftPageNumber + offset);
    const { image: rightImage, isLoading: rightIsLoading } = usePageImage(hasRight ? partNumber : null, rightPageNumber + offset);

    const { pdfData: leftPdf, isLoading: leftPdfIsLoading } = useDocumentPdf(hasLeft && isOversize ? partNumber : null, hasCover ? leftPageNumber + offset + 1 : leftPageNumber + offset);
    const { pdfData: rightPdf, isLoading: rightPdfIsLoading } = useDocumentPdf(hasRight && isOversize ? partNumber : null, hasCover ? rightPageNumber + offset + 1 : rightPageNumber + offset);

    return (
        <OverallContainer documentWidth={documentWidth} hasLeft={hasLeft} hasRight={hasRight}>
            <Stack direction='row' justifyContent='center' style={{ position: 'absolute' }}>
                {leftPageNumber != null && leftPageNumber != undefined ? (
                    <PageContainer documentWidth={documentWidth} documentHeight={documentHeight} side='left'>
                        {leftIsLoading ? <PageSpinner documentHeight={documentHeight} /> : <img src={leftImage} style={{ height: '100%', width: '100%' }} />}
                    </PageContainer>
                ) : null}
                {rightPageNumber != null && rightPageNumber != undefined ? (
                    <PageContainer documentWidth={documentWidth} documentHeight={documentHeight} side='right'>
                        {rightIsLoading ? <PageSpinner documentHeight={documentHeight} /> : <img src={rightImage} style={{ height: '100%', width: '100%' }} />}
                    </PageContainer>
                ) : null}
            </Stack>

            {!forceReload && isOversize && (!leftPdfIsLoading && hasLeft) && (!rightPdfIsLoading && hasRight) ? (
                <div style={{ display: (!leftDocRendered && hasLeft) && (!rightDocRendered && hasRight) ? 'none' : null, position: 'absolute' }}>
                    <Stack direction='row' justifyContent='center'>
                        {!leftPdfIsLoading && !!leftPdf && leftPageNumber != null && leftPageNumber != undefined ? (
                            <Document file={leftPdf} externalLinkTarget="_blank" loading='' onLoadSuccess={(args) => { setTimeout(() => setLeftDocRendered(true), 500); }} onItemClick={({ dest, pageIndex, pageNumber }) => { setPageNumber(pageNumber) }}>
                                <PageContainer documentWidth={documentWidth} documentHeight={documentHeight} side='left'>
                                    <Page loading='' noData='' pageNumber={1} renderTextLayer={true} height={documentHeight} width={documentWidth} renderAnnotationLayer={true} />
                                </PageContainer>
                            </Document>
                        ) : null}
                        
                        {!rightIsLoading && !!rightPdf && rightPageNumber != null && rightPageNumber != undefined ? (
                            <Document file={rightPdf} externalLinkTarget="_blank" loading='' onLoadSuccess={(args) => { setTimeout(() => setRightDocRendered(true), 500); }} onItemClick={({ dest, pageIndex, pageNumber }) => { setPageNumber(pageNumber) }}>
                                <PageContainer documentWidth={documentWidth} documentHeight={documentHeight} side='right'>
                                    <Page loading='' noData='' pageNumber={1} renderTextLayer={true} height={documentHeight} width={documentWidth} renderAnnotationLayer={true} />
                                </PageContainer>
                            </Document>
                        ) : null}
                    </Stack>
                </div>
            ) : null}

            {!forceReload && !isPdfLoading ? (
                <div style={{ display: !docRendered ? 'none' : null, position: 'absolute' }}>
                    <Document file={pdfData} externalLinkTarget="_blank" loading='' onLoadSuccess={(args) => { setTimeout(() => setDocRendered(true), 500) }} onItemClick={({ dest, pageIndex, pageNumber }) => { setPageNumber(pageNumber) }}>
                        <Stack direction='row' justifyContent='center'>
                            {leftPageNumber != null && leftPageNumber != undefined ? (
                                <PageContainer documentWidth={documentWidth} documentHeight={documentHeight} side='left'>
                                    <Page loading='' noData='' pageNumber={leftPageNumber} renderTextLayer={true} height={documentHeight} width={documentWidth} renderAnnotationLayer={true} />
                                </PageContainer>
                            ) : null}
                            {rightPageNumber != null && rightPageNumber != undefined ? (
                                <PageContainer documentWidth={documentWidth} documentHeight={documentHeight} side='right'>
                                    <Page loading='' noData='' pageNumber={rightPageNumber} renderTextLayer={true} height={documentHeight} width={documentWidth} renderAnnotationLayer={true} />
                                </PageContainer>
                            ) : null}
                        </Stack>
                    </Document>
                </div >
            ) : null}
        </OverallContainer>
    );
}
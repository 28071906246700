import React, { forwardRef, useEffect } from "react";
import { PageSpinner } from "../../documentViewCore";
import { preloadPageImage, usePageImage } from "../../../fetch/fetchImage";
import ImagePageContainer from './ImagePageContainer';

const DocumentScrollPagePane = forwardRef(({
    partNumber,
    pageNumber,
    pageCount,
    documentDimensions,
    children,
    isInViewport,
    onToggleZoom
}, ref) => {
    const { image, isLoading: imageIsLoading } = usePageImage(isInViewport ? partNumber : null, pageNumber);

    useEffect(() => {
        if (isInViewport) {
            if (isInViewport && pageNumber - 1 > 0) {
                preloadPageImage(partNumber, pageNumber - 1);
            }

            if (isInViewport && pageNumber < pageCount) {
                preloadPageImage(partNumber, pageNumber + 1);
            }
        }
    }, [isInViewport, pageNumber, partNumber, pageCount])

    return (
        <ImagePageContainer
            documentDimensions={documentDimensions}
            image={image}
            ref={ref}
            onDoubleClick={onToggleZoom}
        >
            {imageIsLoading ? <PageSpinner documentHeight={documentDimensions.documentHeight} /> : null}
            {!!children && isInViewport ? (
                children
            ) : null}
        </ImagePageContainer>
    );
});

export default DocumentScrollPagePane
import { Collapse, List, ListItemButton, ListItemText } from "@mui/material";
import React, { useState } from "react";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

export default function FilterCollapseGroup({ title, children, expandedByDefault }) {
    const [expanded, setExpanded] = useState(expandedByDefault);
    return (
        <List>
            <ListItemButton onClick={() => setExpanded(!expanded)} sx={{ pt: 0, pb: 0 }}>
                <ListItemText primary={title} />
                {expanded ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {children}
            </Collapse>
        </List>
    );
}
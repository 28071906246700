import React, { useEffect, useMemo, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from 'react-redux';
import { fetchPermissions } from '../../redux/permissionsSlice';
import { executeQuery, clearQuery, changeFilter, clearFilters } from '../../redux/libraryFiltersSlice';
import PersonIcon from '@mui/icons-material/Person';
import { useDebouncedCallback } from 'use-debounce';
import { exitFullScreen, isInFullScreen } from '../../helpers/fullScreenHelper';
import { useNotification } from '../../hooks/useNotification';
import DropMenu from './DropMenu';
import { useLanguageList } from '../../fetch/fetchClassificationItems';
import ChangeDefaultLanguageDialog from './ChangeDefaultLanguage';
import NavBarSectionSelect from '../pdfDocumentView/NavBarSectionSelect';
import usePhoneMode from '../../hooks/usePhoneMode';
import { useTheme } from '@mui/material/styles';
import SearchField from '../Search';
import { useDeviceSelectors } from 'react-device-detect';
import { logDisplayDevice } from '../../fetch/insightsHelper';
import ChatIcon from '@mui/icons-material/Chat';
import FeedbackModal from './FeedbackModal';
import DownloadPdfButton from './DownloadPdfButton';

export default function LiteratureNavMenu() {
    const { instance } = useMsal();
    const theme = useTheme();
    const { showPromptModal } = useNotification();
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();
    const [showFeedback, setShowFeedback] = useState(false);

    const logout = () => {
        instance.logoutRedirect();
    }

    const [showLanguageChange, setShowLanguageChange] = useState(false);
    const { isMobile, isLandscape } = usePhoneMode();

    const { pathname } = useLocation();
    const isInDocumentView = pathname.toLowerCase().indexOf('/literature/view') >= 0;
    const isInVideoView = pathname.toLowerCase().indexOf('/literature/video') >= 0;

    const isLoaded = useSelector((state) => state.permissions.isLoaded)

    const [selectors] = useDeviceSelectors()
    const { osName, osVersion, mobileVendor, mobileModel, browserName, browserVersion, deviceType } = selectors;

    const os = `${osName} ${osVersion}`;
    const mobile = !!mobileVendor && mobileVendor != 'none' ? `${mobileVendor} ${mobileModel}` : null;
    const browser = `${browserName} ${browserVersion}`;

    useMemo(() => {

        if (isLoaded) {
            logDisplayDevice(os, mobile, browser, deviceType);
        }
    }, [isLoaded, os, mobile, browser, deviceType]);

    const { languages } = useLanguageList();
    const defaultLanguage = useSelector((state) => state.permissions.language)

    let languageLabel = 'Change Default Language';
    if (!!defaultLanguage && !!languages && languages.length > 0) {
        const filteredLanguages = languages.filter(lang => lang.code == defaultLanguage);
        if (!!filteredLanguages && filteredLanguages.length > 0) {
            languageLabel = `Default Language: ${filteredLanguages[0].nameLocal}`;
        }
    }

    const location = useLocation();

    useEffect(() => {
        if (isAuthenticated && !isLoaded) {
            dispatch(fetchPermissions());
        }
    }, [isAuthenticated, isLoaded]);

    const dispatch = useDispatch();
    const { query: executedQuery, searchTextBoxValue: workingQuery } = useSelector((state) => state.libraryFilters)

    const debouncedExecutedQuery = useDebouncedCallback(() => {
        handleExecuteQuery();
    }, 1000)

    const handleExecuteQuery = () => {
        dispatch(executeQuery(workingQuery));
    }

    const handleClearQuery = () => {
        dispatch(clearQuery());
    }

    const handleFilterValueChanged = (value) => {
        dispatch(changeFilter({ fieldName: 'searchTextBoxValue', value }));
    }

    const handleLogoutClick = () => {
        if (isInFullScreen()) {
            exitFullScreen();
        }

        showPromptModal('Log out?', 'Are you sure you want to log out?', () => {
            dispatch(clearFilters());
            logout();
        })
    }

    const handleHomeClick = () => {
        if (isInFullScreen()) {
            exitFullScreen();
        }

        navigate('/literature');
    }

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="fixed" style={{ height: theme.appBar.height }}>
                    <Toolbar>
                        <img title='Mercury Marine' className='logo' src='wavelogo.png' style={{ maxHeight: '1.25em', marginRight: '0.5em' }} />
                        {!isMobile ? (
                            <Typography variant="h6" component="div" noWrap>
                                Literature
                            </Typography>
                        ) : null}
                        <Box sx={{ flexGrow: 1 }} />
                        {location.pathname == '/literature' ? (
                            <SearchField
                                placeholder='Seach...'
                                query={workingQuery}
                                onChange={(event) => {
                                    handleFilterValueChanged(event.target.value);
                                    debouncedExecutedQuery();
                                }}
                                onKeyDown={(event) => {
                                    if (event.key == 'Enter') {
                                        handleExecuteQuery();
                                    }
                                }}
                                onClearQuery={handleClearQuery}
                                showClear={!!executedQuery}
                            />
                        ) : null}
                        {isInDocumentView ? (
                            <>
                                <NavBarSectionSelect />
                                <Box sx={{ flexGrow: 1 }} />
                            </>
                        ) : null}
                        {isInDocumentView || isInVideoView ? (
                            <>
                                {isInDocumentView ? <DownloadPdfButton /> : null}
                                <Button
                                    color="inherit"
                                    title='Send Feedback'
                                    onClick={() => setShowFeedback(!showFeedback)}
                                >
                                    <ChatIcon />
                                </Button>
                                <Button color="inherit" onClick={handleHomeClick}>Close</Button>
                            </>) : null}
                        {isAuthenticated && !isInDocumentView && !isInVideoView ? (
                            <>
                                <Box sx={{ flexGrow: 1 }} />
                                <Button
                                    color="inherit"
                                    title='Send Feedback'
                                    onClick={() => setShowFeedback(!showFeedback)}
                                >
                                    <ChatIcon />
                                </Button>
                                <DropMenu
                                    label={<PersonIcon />}
                                    subItems={[
                                        { label: languageLabel, onClick: () => setShowLanguageChange(true) },
                                        { label: 'Log Out', onClick: handleLogoutClick }
                                    ]}
                                />
                            </>
                        ) : null}
                    </Toolbar>
                </AppBar>
            </Box>
            <ChangeDefaultLanguageDialog
                open={showLanguageChange}
                onClose={() => setShowLanguageChange(false)}
            />
            <FeedbackModal
                open={showFeedback}
                onClose={() => setShowFeedback(false)}
            />
        </>
    );
}
import useSWR, { preload } from 'swr';
import { getAuthenticatedHeaders } from './fetchHeaders';

export const preloadDocumentPdf = (partNumber, pageIndex) => {
    const url = getPdfPath(partNumber, pageIndex);
    if (!url) {
        return;
    }
    preload(url, imageFetcher);
}

export const useDocumentPdf = (partNumber, pageNumber) => {
    const { data, error, isValidating, isLoading, mutate } = useSWR(
        getPdfPath(partNumber, pageNumber),
        imageFetcher,
        swrConfig);

    return { pdfData: data, error, isValidating, isLoading, mutate };
};

const getPdfPath = (partNumber, pageNumber) => {
    if (!pageNumber || !partNumber) {
        return null;
    }

    return `/document/pdf/${partNumber}/page/page_${pageNumber}.pdf`;
}

const imageFetcher = (url) => {
    return getAuthenticatedHeaders().then((headers) => {
        return fetch(url, { headers })
            .then(response => response.blob())
    });
}

export const useDocumentSectionPdf = (partNumber, fileName) => {
    const { data, error, isValidating, isLoading, mutate } = useSWR(
        getPdfSectionPath(partNumber, fileName),
        imageFetcher,
        swrConfig);

    return { pdfData: data, error, isValidating, isLoading, mutate };
};

export const preloadDocumentSectionPdf = (partNumber, fileName) => {
    const url = getPdfSectionPath(partNumber, fileName);
    if (!url) {
        return;
    }
    preload(url, imageFetcher);
}

const getPdfSectionPath = (partNumber, fileName) => {
    if (!fileName || !partNumber) {
        return null;
    }

    return `/document/pdf/${partNumber}/section/${fileName}`;
}

const swrConfig = {
    revalidateOnFocus: false,
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Never retry on 4XX error.
        if (error.status >= 400 && error.status < 500) return;

        //   // Never retry for a specific key.
        //   if (key === '/api/user') return

        // Only retry up to 1 times.
        if (retryCount > 1) return;

        // Retry after 1 seconds.
        setTimeout(() => { revalidate({ retryCount }); }, 1000)
    }
};
import React, { useState } from 'react';
import { Box, Button, CircularProgress, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material';
import { useDealerExists, useLicenseKeyValid } from '../../fetch/userFetch';
import { httpResponseToJSON } from '../../helpers/fetchHelpers';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { startReload } from '../../redux/permissionsSlice';
import { useLanguageList } from '../../fetch/fetchClassificationItems';
import { storageHelper } from '../../helpers/storageHelper';
import { parameterHelper } from '../../helpers/parameterHelper';
import { getAuthenticatedHeaders } from '../../fetch/fetchHeaders';

export default function LinkAccount() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isRecognized = useSelector((state) => state.permissions.isRecognized);
    const { redirect: redirectUri } = parameterHelper.getUrlParameters({ redirect: null });
    let activationKey = storageHelper.getActivationKey();

    if (!!activationKey) {
        try {
            activationKey = atob(activationKey)
        }
        catch {
            activationKey = activationKey;
        }
    }

    const { languages } = useLanguageList();

    const [dealerNumber, setDealerNumber] = useState('');
    const [licenseKey, setLicenseKey] = useState('');
    const [language, setLanguage] = useState('en');
    const [validating, setValidating] = useState(false);

    const { exists: dealerExists, isLoading: isDealerExistsLoading } = useDealerExists(dealerNumber);
    const { exists: licenseKeyExists, isLoading: isLicenseKeyExistsLoading } = useLicenseKeyValid(dealerNumber, licenseKey, dealerExists);

    const validateUser = () => {
        if ((!dealerExists || !licenseKeyExists) && !activationKey) {
            return;
        }

        setValidating(true);
        let url = `/user/Associate/${licenseKey}/${dealerNumber}?language=${language}`;
        if (!!activationKey) {
            url = `/user/AssociateFromG3/${activationKey}?language=${language}`
        }

        return getAuthenticatedHeaders().then((headers) => {
            return fetch(url, { headers })
                .then(response => httpResponseToJSON(response, 'Error loading data'/*, showError*/))
                .then((data) => {
                    if (data.status == 4 || data.status == 6) {
                        dispatch(startReload())
                        navigate(!redirectUri ? '/literature' : redirectUri);
                    } else {
                        // Theoretical impossible but error
                        // If we do get an error something might be wrong with the activation
                        // key so clear it out so the user isn't stuck
                        storageHelper.setActivationKey('');
                        throw new Error('Error validating user');
                    }
                });
        }).finally(() => {
            setValidating(false);
        });
    }

    if (isRecognized) {
        return <Navigate to="/" />;
    }

    return (
        <Grid container spacing={2} padding={2}>
            <Grid item xs={6}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%'
                    }}
                >
                    {!activationKey ?
                        'This user is is not associated with an account number. Use A G3 license to validate your association with a dealer.'
                        : 'It looks like you\'ve not signed into the G3 Literature yet, please verify your preferred manual language and continue'}
                    <br />
                    <br />
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '50ch' },
                            '& .MuiFormControl-root': { m: 1, width: '50ch' },
                        }}
                        autoComplete="off"
                    >
                        {!activationKey ? (
                            <>
                                <TextField
                                    required
                                    id='dealerNumber'
                                    label='Dealer Number'
                                    value={dealerNumber}
                                    error={!dealerExists && !!dealerNumber}
                                    helperText={!dealerExists && !!dealerNumber && !isDealerExistsLoading ? 'Unrecognized dealer' : null}
                                    onChange={(event) => setDealerNumber(event.target.value)}
                                    InputProps={{
                                        startAdornment: isDealerExistsLoading ? (<InputAdornment position="start"><CircularProgress size={20} /></InputAdornment>) : null,
                                    }}
                                />
                                <TextField
                                    required
                                    id='licenseKey'
                                    label='License Key'
                                    value={licenseKey}
                                    error={!licenseKeyExists && !!licenseKey}
                                    helperText={!licenseKeyExists && !!licenseKey && !isLicenseKeyExistsLoading ? 'Unrecognized license key' : null}
                                    onChange={(event) => setLicenseKey(event.target.value)}
                                    InputProps={{
                                        startAdornment: isLicenseKeyExistsLoading ? (<InputAdornment position="start"><CircularProgress size={20} /></InputAdornment>) : null,
                                    }}
                                />
                            </>
                        ) : null}
                        <FormControl>
                            <InputLabel id='language-select-label'>Preferred Language</InputLabel>
                            <Select
                                labelId='language-select-label'
                                id='language-select-label'
                                value={language}
                                label="Language"
                                onChange={(event) => setLanguage(event.target.value)}
                            >
                                {languages.map(item => {
                                    return (
                                        <MenuItem key={item.id + item.nameLocal} value={item.code}>{item.nameLocal}</MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        {!validating ? (
                            <Button disabled={(!licenseKeyExists || !dealerExists) && !activationKey} onClick={() => validateUser()}>{!activationKey ? 'Validate' : 'Continue'}</Button>
                        ) : (
                            <CircularProgress />
                        )}
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    )
}
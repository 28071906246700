import { useDispatch } from "react-redux";
import { showError, showPrompt } from '../redux/notificationsSlice';

export function useNotification() {
    const dispatch = useDispatch();

    const showErrorModal = (message) => {
        dispatch(showError({ message }));
    }

    const showPromptModal = (title, message, onAccept, OnCancel) => {
        dispatch(showPrompt({ title, message, onAccept, OnCancel }));
    }

    return { showErrorModal, showPromptModal };
}
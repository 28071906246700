export function httpResponseToJSON(response, errorMessage, showError) {
    if (response.status !== 200) {
        let error;
        if (!!errorMessage) {
            error = new Error(errorMessage);
        } else {
            error = new Error(`${response.status} ${response.statusText}`);
        }

        if (showError) {
            showError(error.message);
        } else {
            console.error(error.message)
        }

        error.status = response.status;

        if (!showError) {
            throw error;
        }
    }

    return response.json();
}
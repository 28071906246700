import React, { useState } from "react";
import ContentContainer from "./contentContainer";
import { Box, Grid, InputLabel, TextField, Alert } from "@mui/material";
import PageTitle from "./pageTitle";
import { apiHostUrl, parameterHelper } from "../../helpers";
import { useDealerExistsAnon } from "../../fetch/userFetch";
import Spinner from "../spinner/Spinner";
import { useIsLicenseKeyValidForDownload } from "../../fetch/licenseKeyFetch";

const isKeyValid = (licenseKey) => {
    return /^([A-Za-z0-9]{5}-){4}[A-Za-z0-9]{5}$/.test(licenseKey.replaceAll(/\_/g, ''));
}

const defaultParameters = { dealerNumber: '', licenseKey: '' };

export default function G3Download() {
    const [isWindows, setIsWindows] = useState(navigator.userAgent.indexOf('Windows') >= 0);
    const [isArm, setIsArm] = useState(false);
    if (!!navigator.userAgentData && !!navigator.userAgentData.getHighEntropyValues) {
        navigator.userAgentData.getHighEntropyValues(["architecture", "bitness"])
            .then(ua => {
                if (navigator.userAgentData.platform === "Windows") {
                    setIsWindows(true);
                    if (ua.architecture === 'x86') {
                        setIsArm(false);
                        // if (ua.bitness === '64') {
                        //     console.log("x86_64");
                        // }
                        // else if (ua.bitness === '32') {
                        //     console.log("x86");
                        // }
                    }
                    else if (ua.architecture === 'arm') {
                        setIsArm(true);
                        // if (ua.bitness === '64') {
                        //     console.log("ARM64");
                        // }
                        // else if (ua.bitness === '32') {
                        //     console.log("ARM32");
                        // }
                    }
                }
                else {
                    // console.log("Not running on Windows");
                    setIsWindows(false);
                }
            });
    }

    const { dealerNumber: prefilledDealerNumber, licenseKey: prefilledValue } = parameterHelper.getUrlParameters(defaultParameters);

    let licenseKeyParsed = '';
    try {
        licenseKeyParsed = atob(prefilledValue)
    }
    catch {
        licenseKeyParsed = prefilledValue;
    }

    const [state, setState] = useState({
        dealerNumber: prefilledDealerNumber,
        licenseKey: licenseKeyParsed
    });

    const { dealerNumber, licenseKey } = state;
    const keyIsValid = isKeyValid(licenseKey);

    const { exists: dealerValidationValue, isLoading: dealerIsValidating } = useDealerExistsAnon(dealerNumber);
    const isDealerValid = !!dealerValidationValue;

    const { data: licenseKeyStatus, isLoading: licenseKeyIsValidating } = useIsLicenseKeyValidForDownload(dealerNumber, licenseKey, isDealerValid);
    let validated = false,
        isExpired = false,
        isG3Version = false,
        installerFilename = null,
        productName = '',
        noSeats = false,
        isValid = false;
    if (!!licenseKeyStatus) {
        validated = true;
        isExpired = licenseKeyStatus?.status == 2;
        isG3Version = licenseKey.isG3Version;
        productName = licenseKeyStatus.name;
        noSeats = licenseKeyStatus.status == 6;
        if (licenseKeyStatus.status == 4 && licenseKeyStatus.isG3Version) {
            isValid = true;
            installerFilename = licenseKeyStatus.installerFilename
        }
    }
    const downloadUrl = `${apiHostUrl}/api/v1/file/g3/${licenseKey}/${dealerNumber}`;

    const handleDealerNumberChange = (event) => {
        const dealerNumber = event.target.value.replaceAll(/\//g, '');
        setState({ ...state, dealerNumber });
    }

    const handleLicenseKeyChange = (licenseKey) => {
        const inputLicenseKey = licenseKey;

        let outputLicenseKey = '';
        let segment = '';

        for (let i = 0; i < inputLicenseKey.length; i++) {
            const char = inputLicenseKey[i];

            if (/\w/.test(char) && char != '-') {
                segment += char;
            }

            if (segment.length == 5) {
                outputLicenseKey += segment;
                if (i !== 28) {
                    outputLicenseKey += '-';
                }
                segment = '';
            }

        }

        if (segment.length > 0) {
            outputLicenseKey += segment;
        }

        setState({ ...state, licenseKey: outputLicenseKey });
    }

    let keyText = '';
    if (validated && !isValid && !isExpired && (!!isG3Version || !productName) && !noSeats) {
        keyText = 'Dealer/License Key combination invalid';
    }
    else if (validated && !isValid && !isExpired && (!!isG3Version || !productName) && noSeats) {
        keyText = (
            <span>
                The license key entered has exceeded the number of activations allowed. <a href={`/contact?AccountNumber=${dealerNumber}&licenseKey=${btoa(licenseKey)}`}>Click here to contact support.</a>
                <br /><br />
                <b>Note:</b> You can also release your license by opening Mercury CDS G3, go to Help, About then Deactivate Machine.
            </span>)
    }
    else if (validated && !isValid && isExpired && (!!isG3Version || !productName)) {
        keyText = 'License key expired or product version has reached end of life';
    }
    else if (validated && !isValid && !isG3Version && !!productName) {
        keyText = `The key entered matches the ${productName} smartphone app. Please enter a valid CDS G3 license key to download the application.`;
    }

    return (
        <ContentContainer>
            <PageTitle title='Download' />
            <Box component="main" sx={{ p: 3, width: '100%', marginTop: '-2em' }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <div>
                            <h3>CDS G3 is&nbsp;compatible with Windows 11.&nbsp;</h3>
                            {!isWindows ? (
                                <Alert severity="error">
                                    This computer appears to not be running Windows. G3 cannot run on this device</Alert>
                            ) : null}
                            {isWindows && isArm ? (
                                <Alert severity="error">
                                    This computer appears to have an ARM processor. G3 cannot run on this device</Alert>
                            ) : null}
                            <p><em>We strongly encourage our users to upgrade to a version of Windows that is supported by Microsoft. Unsupported operating systems receive no security updates, have known exploits, and are dangerous for you to use.</em></p>
                            <p><a href="/g3/support/system-requirements">Click Here for&nbsp;CDS G3 System Requirements.</a></p>
                            <div>
                                Instructions:
                                <ol>
                                    <li>Please disconnect Smartcraft & Serial Interface from usb before installing software</li>
                                    <li>Enter the dealer number and the license key number</li>
                                    <li>Click download (if prompted, save the download)</li>
                                    <li>Find and double-click the downloaded file or open the file from the downloads list.</li>
                                    <li>If prompted, click “Install” and “Yes” on the system dialogs.</li>
                                    <li>Once the installation has finished, your G3 software is ready to launch.</li>
                                    <li>Use the License Key, along with your Mercury dealer number to activate the software on first start up</li>
                                </ol>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={2}>
                        <InputLabel htmlFor='dealerNumber' style={{ padding: '0.5em 0' }}>Dealer Number</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            required
                            error={!isDealerValid && !!dealerNumber}
                            value={dealerNumber}
                            id='dealerNumber'
                            onChange={handleDealerNumberChange}
                            size="small"
                            inputProps={{ maxLength: 50 }}
                        />
                        {dealerIsValidating ? <Spinner displayInline width='2.1em' /> : null}
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={2}>
                        <InputLabel htmlFor='licenseKey' style={{ padding: '0.5em 0' }}>License Key</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            required
                            error={!!licenseKey && (!keyIsValid || !isValid)}
                            helperText={keyText}
                            value={licenseKey}
                            id='licenseKey'
                            onChange={(event) => handleLicenseKeyChange(event.target.value)}
                            size="small"
                            style={{ width: '20em' }}
                            inputProps={{ maxLength: 29 }}
                        />
                        {licenseKeyIsValidating ? <Spinner displayInline width='2.1em' /> : null}
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={10}>
                        {!!installerFilename && isWindows && !isArm ? <a className='MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1y15k5x-MuiButtonBase-root-MuiButton-root' href={downloadUrl} download={installerFilename}>Download</a> : null}
                    </Grid>
                </Grid>
            </Box>
        </ContentContainer>
    );
}
import React, { useState } from "react";
import ContentContainer from "./contentContainer";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, TextField } from "@mui/material";
import PageTitle from "./pageTitle";
import { useModelState } from '../../hooks';
import { parameterHelper, apiHostUrl } from "../../helpers";
import { useDealerExistsAnon } from "../../fetch/userFetch";
import Spinner from "../spinner/Spinner";

const defaultState = { dealerNumber: '', serialNumber: '', email: '', phone: '', hullId: '', subject: '', description: '', g3Version: '', name: '' };
const emailRegEx = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
const defaultParameters = { AccountNumber: '', G3Version: '', SnapshotId: null, licenseKey: '' };

export default function ContactForm() {
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    const { AccountNumber: prefilledAccountNumber, G3Version: prefilledG3Version, SnapshotId: snapshotId, licenseKey: prefilledLicenseKey } = parameterHelper.getUrlParameters(defaultParameters);
    if (!!prefilledLicenseKey) {
        const licenseKey = atob(prefilledLicenseKey);
        defaultState.subject = 'License Key Exceeded';
        defaultState.description = `License key ${licenseKey} has exceeded the number of activations allowed.`;
    }
    const [model, handleValueChanged, setModelState] = useModelState({ ...defaultState, dealerNumber: prefilledAccountNumber, g3Version: prefilledG3Version, snapshotId });

    const { dealerNumber, name, email, phone, serialNumber, hullId, subject, description, g3Version } = model;

    const { exists: dealerValidationValue } = useDealerExistsAnon(dealerNumber);
    const isValidated = dealerValidationValue != null;
    const isDealerValid = !!dealerValidationValue;

    let isEmailValid = false;
    if (!!email) {
        isEmailValid = email.match(emailRegEx);
    }

    const isValid = isEmailValid && !!subject && !!description && isDealerValid && !!name && !saving;

    const handleSubmitClick = () => {
        setSaving(true);

        fetch(`${apiHostUrl}/api/v1/contact`,
            {
                method: 'POST',
                body: JSON.stringify(model),
                headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
                if (!response.ok) {
                    console.log(response);
                    setError('Error submitting contact');
                } else {
                    setModelState(defaultState);
                    window.location.replace('/')
                }
            }, (error) => {
                setError('Error submitting contact');
            }).finally(() => {
                setSaving(false);
            });
    }

    return (
        <ContentContainer>
            <PageTitle title='Contact' />

            <Dialog
                open={!!error}
                onClick={() => setError(null)}
            >
                <DialogTitle>Error</DialogTitle>
                <DialogContent>{error}</DialogContent>
                <DialogActions>
                    <Button onClick={() => setError(null)}>Ok</Button>
                </DialogActions>
            </Dialog>

            <Box component="main" sx={{ p: 3, width: '100%' }}>
                {saving ? (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Spinner />
                        </Grid>
                    </Grid>
                ) : null}
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <InputLabel htmlFor='dealerNumber' style={{ textAlign: 'right', padding: '0.5em 0' }}>Dealer Number</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            required
                            error={!isDealerValid && isValidated}
                            value={dealerNumber}
                            id='dealerNumber'
                            onChange={(event) => handleValueChanged('dealerNumber', event.target.value)}
                            fullWidth
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <InputLabel htmlFor='name' style={{ textAlign: 'right', padding: '0.5em 0' }}>Name</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            required
                            error={!name}
                            id='name'
                            value={name}
                            onChange={(event) => handleValueChanged('name', event.target.value)}
                            fullWidth
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <InputLabel htmlFor='email' style={{ textAlign: 'right', padding: '0.5em 0' }}>Email</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            required
                            error={!isEmailValid}
                            value={email}
                            id='email'
                            onChange={(event) => handleValueChanged('email', event.target.value)}
                            fullWidth
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <InputLabel htmlFor='phone' style={{ textAlign: 'right', padding: '0.5em 0' }}>Phone</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            id='phone'
                            value={phone}
                            onChange={(event) => handleValueChanged('phone', event.target.value)}
                            fullWidth
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <InputLabel htmlFor='serialNumber' style={{ textAlign: 'right', padding: '0.5em 0' }}>Serial Number</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            value={serialNumber}
                            id='serialNumber'
                            onChange={(event) => handleValueChanged('serialNumber', event.target.value)}
                            fullWidth
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <InputLabel htmlFor='hullId' style={{ textAlign: 'right', padding: '0.5em 0' }}>Hull ID</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            id='hullId'
                            value={hullId}
                            onChange={(event) => handleValueChanged('hullId', event.target.value)}
                            fullWidth
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <InputLabel htmlFor='subject' style={{ textAlign: 'right', padding: '0.5em 0' }}>Subject</InputLabel>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            required
                            error={!subject}
                            id='subject'
                            value={subject}
                            onChange={(event) => handleValueChanged('subject', event.target.value)}
                            fullWidth
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <InputLabel htmlFor='description' style={{ textAlign: 'right', padding: '0.5em 0' }}>Description</InputLabel>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            required
                            error={!description}
                            id='description'
                            value={description}
                            onChange={(event) => handleValueChanged('description', event.target.value)}
                            fullWidth
                            multiline
                            rows={3}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <InputLabel htmlFor='g3Version' style={{ textAlign: 'right', padding: '0.5em 0' }}>G3 Version</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            value={g3Version}
                            id='g3Version'
                            onChange={(event) => handleValueChanged('g3Version', event.target.value)}
                            fullWidth
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={9}>
                        <Button
                            disabled={!isValid}
                            onClick={handleSubmitClick}
                        // style={{color: '#000'}}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </ContentContainer>
    );
}
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDocumentMetadata } from '../../fetch/fetchMetadata';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import { logDocumentOpen } from '../../fetch/insightsHelper';

import { calculateDocumentPaneSize, DocumentNotFound, DocumentLoading } from '../documentViewCore';
import { useSelector } from 'react-redux';
import HasCoverDocumentView from './HasCoverDocument/HasCoverDocumentView';
import NoCoverDocumentView from './NoCoverDocument/NoCoverDocumentView';
import { useCheckForPassedInFamilyFilter, useIsSinglePage, useWindowDimensions } from '../../hooks';
import { parameterHelper } from '../../helpers';
import DocumentScrollView from '../scrollView/DocumentScrollView';
import { useSwitchFromScrollToSideBySide } from '../scrollView/scrollHelpers';

const defaultParameters = { page: null };
export default function DocumentView() {
  const { page } = parameterHelper.getUrlParameters(defaultParameters);
  const { height: viewportHeight, width: viewportWidth } = useWindowDimensions();
  const userLanguage = useSelector((state) => state.permissions.language);
  const { partNumber } = useParams();

  useCheckForPassedInFamilyFilter();

  const [documentDimensions, setDocumentDimensions] = useState({ documentHeight: viewportHeight, documentWidth: viewportHeight / 1.3 });

  const { metadata, isLoading: isMetadataLoading, error } = useDocumentMetadata(partNumber);
  const { isSinglePageMode } = useIsSinglePage();
  useSwitchFromScrollToSideBySide(!isSinglePageMode && !!metadata && !isMetadataLoading);
  const { hasCover, title, sections, pages } = metadata;
  let pageRatio = null;
  if (!!pages && pages.length > 0) {
    pageRatio = pages[(!hasCover ? 0 : 1)].pageRatio;
  }

  useEffect(() => {
    setDocumentDimensions(calculateDocumentPaneSize(
      false,
      false,
      false,
      viewportWidth,
      viewportHeight,
      1.3));
  }, [viewportHeight, viewportWidth]);

  const defaultPage = hasCover ? -1 : 1;
  const currentPage = !!page ? page : defaultPage;
  const currentSection = sections?.filter(it => it.start <= currentPage && it.end >= currentPage)[0];

  useMemo(() => {
    if (!!userLanguage && !error) {
      const { activationKey } = parameterHelper.getUrlParameters({ activationKey: null });
      logDocumentOpen(partNumber, userLanguage, currentSection?.fileName, !!activationKey);
    }
  }, [partNumber, userLanguage, currentSection?.fileName, error]);

  useEffect(() => {
    if (!!title) {
      document.title = title;
    } else {
      document.title = 'Mercury Literature';
    }
  }, [title]);

  if (!!error) {
    return (
      <DocumentNotFound error={error} />
    );
  }

  if (isSinglePageMode) {
    return (
      <DocumentScrollView />
    );
  }

  if (isMetadataLoading || hasCover === undefined || !pageRatio) {
    return (
      <DocumentLoading documentHeight={documentDimensions.documentHeight} />
    );
  }

  if (hasCover) {
    return <HasCoverDocumentView partNumber={partNumber} metadata={metadata} pageRatio={pageRatio} />
  } else {
    return <NoCoverDocumentView partNumber={partNumber} metadata={metadata} pageRatio={pageRatio} />
  }
}
import { Button } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFamilyNameList } from '../../../fetch/fetchClassificationItems';
import { changeFilter, clearFilters } from '../../../redux/libraryFiltersSlice';
import FilterListIcon from '@mui/icons-material/FilterList';
import CancelIcon from '@mui/icons-material/Cancel';
import usePhoneMode from '../../../hooks/usePhoneMode';
import { useTheme } from '@emotion/react';
import FilterClearButton, { ClearButton, FilteredSearchToggleButton } from './FilterButtons';

export default function ListFiltersButton({ onToggleOpenClick }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { isMobile } = usePhoneMode();
    const { families, type: documentType, group: businessGroup, state: documentState, year: filteredYears } = useSelector((state) => state.libraryFilters)
    const litStates = useSelector((state) => state.permissions.litStates)
    const businessGroups = useSelector((state) => state.permissions.businessGroups)
    const { familyNames } = useFamilyNameList();

    const isNonSearchFiltered = families.length > 0 || documentType != '' || (businessGroup.length != 0 && businessGroup.length != businessGroups.length - 1) || documentState != 3;

    let filteredDocumentType = null;
    if (documentType != '') {
        if (documentType == '2,9') {
            filteredDocumentType = 'Bulletins';
        } else {
            filteredDocumentType = 'Manuals';
        }
    }

    let filteredFamilyName = null;
    if (families.length === 1 && !!familyNames) {
        filteredFamilyName = familyNames.filter(it => it.id == families[0]).map(it => it.name)[0];
    }
    else if (families.length > 1 && !!familyNames) {
        filteredFamilyName = `${families.length} Families`;
    }

    let filteredDocumentState = null;
    if (documentState != 3 && !!litStates) {
        filteredDocumentState = litStates.filter(it => it.id == documentState).map(it => it.description)[0];
    }

    let filteredBusinessGroups = null;
    if (businessGroup != null && businessGroup.length != 0 && businessGroup.length != businessGroups.length) {
        if (businessGroup.length == 1) {
            filteredBusinessGroups = businessGroups.filter(it => it.id == businessGroup[0]).map(it => it.name)[0];
        }
        else {
            filteredBusinessGroups = `${businessGroup.length} Groups`;
        }
    }

    if (isNonSearchFiltered) {
        return (
            <>
                <FilteredSearchToggleButton>
                    <FilterListIcon onClick={onToggleOpenClick} />
                    <FilterClearButton
                        title='Clear Document Type'
                        onClearClick={() => {
                            dispatch(changeFilter({ fieldName: 'type', value: '' }));
                            dispatch(changeFilter({ fieldName: 'year', value: '' }));
                        }}
                        displayValue={filteredDocumentType}
                        onToggleOpenClick={onToggleOpenClick}
                    />
                    <FilterClearButton
                        title='Clear Family Name'
                        onClearClick={() => dispatch(changeFilter({ fieldName: 'families', value: [] }))}
                        displayValue={filteredFamilyName}
                        onToggleOpenClick={onToggleOpenClick}
                    />
                    <FilterClearButton
                        title='Clear Document State'
                        onClearClick={() => dispatch(changeFilter({ fieldName: 'state', value: 3 }))}
                        displayValue={filteredDocumentState}
                        onToggleOpenClick={onToggleOpenClick}
                    />
                    <FilterClearButton
                        title='Clear Business Group'
                        onClearClick={() => dispatch(changeFilter({ fieldName: 'group', value: [] }))}
                        displayValue={filteredBusinessGroups}
                        onToggleOpenClick={onToggleOpenClick}
                    />
                    <FilterClearButton
                        title='Clear Years'
                        onClearClick={() => dispatch(changeFilter({ fieldName: 'year', value: '' }))}
                        displayValue={filteredYears}
                        onToggleOpenClick={onToggleOpenClick}
                    />
                    {(!!filteredFamilyName || !!filteredDocumentState ||
                        !!filteredBusinessGroups) && isMobile ? (
                        <>
                            <span style={{ fontSize: '0.8em', margin: '0 0.25em' }}>Filtered</span>
                            <ClearButton
                                onClick={() => dispatch(clearFilters())}
                                title='Clear Filters'
                            >
                                <CancelIcon />
                            </ClearButton>
                        </>
                    ) : null}
                </FilteredSearchToggleButton>
            </>
        );
    }

    return (
        <Button
            onClick={onToggleOpenClick}
            color='primary'
            title='Filters'
            variant='contained'
            style={{ margin: '6px 16px', minWidth: '24px' }}
        >
            <FilterListIcon />
        </Button>
    )
}
import { ListItemButton } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import TocItemText from './TocEntryText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

export default function TocEntryButton({ onClick, title, isPageShowing, isExpandable, isOpen, onToggleOpen }) {
    const ref = useRef(null);

    let expander = null;
    if (isExpandable) {
        expander = isOpen ? <ExpandLess onClick={(event) => {
            event.stopPropagation();
            onToggleOpen();
        }} /> : <ExpandMore onClick={(event) => {
            event.stopPropagation();
            onToggleOpen();
        }} />;
    }

    const handleClick = () => {
        onClick();

        const node = ref.current;
        node.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center'
        })
    }

    useEffect(() => {
        if (isPageShowing) {
            const node = ref.current;
            node.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center'
            });

            if (!isOpen && isExpandable) {
                onToggleOpen();
            }
        }
    }, [isPageShowing])

    return (
        <ListItemButton
            onClick={handleClick}
            ref={ref}
        >
            <TocItemText
                isPageShowing={isPageShowing}
                title={title} />

            {expander}
        </ListItemButton>
    );
}
import useSWR from 'swr';
import { useIsAuthenticated } from "@azure/msal-react";
import { httpResponseToJSON } from '../helpers/fetchHelpers';
import { getAuthenticatedHeaders } from './fetchHeaders';

export const useFamilyNameList = () => {
    const { data, error, isValidating, mutate } = useSWR(
        'fetchFamilyNameList',
        () => getAuthenticatedHeaders().then((headers) => {
            return fetch('/document/FamilyNames', { headers })
                .then(response => httpResponseToJSON(response, 'Error loading family name list'/*, showError*/))
        }),
        swrConfig);

    const familyNames = data || [];

    return { familyNames, error, isValidating, mutate };
};

export const useLanguageList = () => {
    const isAuthenticated = useIsAuthenticated();
    const { data, error, isValidating, isLoading, mutate } = useSWR(
        isAuthenticated ? 'fetchLanguageList' : null,
        () => getAuthenticatedHeaders().then((headers) => {
            return fetch('/document/Languages', { headers })
                .then(response => httpResponseToJSON(response, 'Error loading language list'/*, showError*/))
        }),
        swrConfig);

    const languages = data || [];

    return { languages, error, isValidating, isLoading, mutate };
};

export const useDocumentTypeList = () => {
    const { data, error, isValidating, mutate } = useSWR(
        'fetchDocumentTypeList',
        () => getAuthenticatedHeaders().then((headers) => {
            return fetch('/document/DocumentTypes', { headers })
                .then(response => httpResponseToJSON(response, 'Error loading document type list'/*, showError*/))
        }),
        swrConfig);

    const documentTypes = data || [];

    return { documentTypes, error, isValidating, mutate };
};

export const useBusinessGroupList = () => {
    const { data, error, isValidating, mutate } = useSWR(
        'fetchBusinessGroupList',
        () => getAuthenticatedHeaders().then((headers) => {
            return fetch('/document/BusinessGroups', { headers })
                .then(response => httpResponseToJSON(response, 'Error loading business group list'/*, showError*/))
        }),
        swrConfig);

    const businessGroups = data || [];

    return { businessGroups, error, isValidating, mutate };
};

const swrConfig = {
    revalidateOnFocus: false,
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Never retry on 4XX error.
        if (error.status >= 400 && error.status < 500) return;

        //   // Never retry for a specific key.
        //   if (key === '/api/user') return

        // Only retry up to 1 times.
        if (retryCount > 1) return;

        // Retry after 1 seconds.
        setTimeout(() => { revalidate({ retryCount }); }, 1000)
    }
};
import React, { useEffect, useState } from 'react';
import { AppBar, Dialog, IconButton, Slide, Stack, Toolbar, Typography } from '@mui/material';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import useWindowDimensions from '../../hooks/useWindowDimensions';
import CloseIcon from '@mui/icons-material/Close';
import PdfView from './DocumentPage/PdfView';

export default function ZoomDialog({ open, onClose, partNumber, currentSection,
    sectionOneHasNextPage, currentPage, currentPageOffset, zoomRotation, zoomScale,
    isSinglePageMode, pageRatio, nextSection, nextPageOffset, hasCover }) {

    const sectionTwoHasPage = !sectionOneHasNextPage && !isSinglePageMode && !!nextSection;

    const { height: viewportHeight, width: viewportWidth } = useWindowDimensions();
    const [documentDimensions, setDocumentDimensions] = useState({ documentHeight: viewportHeight, documentWidth: viewportHeight / pageRatio });
    const { documentHeight, documentWidth } = documentDimensions;

    const absoluteRotation = Math.abs(zoomRotation);

    const isHorizontal = (absoluteRotation == 90 || absoluteRotation == 270);

    useEffect(() => {
        setDocumentDimensions(calculateDocumentPaneSize(
            isHorizontal,
            isSinglePageMode,
            viewportWidth,
            viewportHeight,
            pageRatio));
    }, [viewportHeight, viewportWidth, isHorizontal, isSinglePageMode, pageRatio]);

    const containerWidth = isHorizontal ? documentWidth : documentHeight;
    const containerHeight = isHorizontal ? documentHeight : documentWidth;

    let verticalLeft = null;
    if (zoomRotation == 270) {
        verticalLeft = documentHeight / 2;
    }

    let verticalTop = '70px';
    if (isHorizontal) {
        verticalTop = `${(documentWidth / 4) + 140}px`;
        if (zoomRotation == 270) {
            verticalTop = `${(documentWidth * .75) + 140}px`;
        }
    }

    const pageCalculatedWidth = (documentWidth * .769) * zoomScale
    const pageCalculatedHeight = documentHeight * zoomScale;
    let leftPadding = (pageCalculatedWidth * 2) > viewportWidth ? ((pageCalculatedWidth * 2) - viewportWidth) : null;
    if (isSinglePageMode || sectionTwoHasPage) {
        leftPadding = pageCalculatedWidth > viewportWidth ? pageCalculatedWidth - viewportWidth : null
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            scroll='paper'
            sx={{ height: 'calc(100vh - 45px)' }}
            PaperProps={{
                style: {
                    backgroundColor: '#121212'
                }
            }}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge='start'
                        color='inherit'
                        onClick={onClose}
                        aria-label='close'
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Zoom
                    </Typography>
                </Toolbar>
            </AppBar>
            <div style={{
                position: 'absolute',
                width: '100%',
                height: containerHeight,
                left: verticalLeft,
                top: verticalTop,
                overflow: 'hidden'
            }}>
                <div
                    style={{
                        width: '100%',
                        minHeight: '100%',
                        height: containerHeight,
                        transform: `rotate(${zoomRotation}deg)`,
                        transformOrigin: `${zoomRotation == 270 || zoomRotation == -90 ? ((containerHeight * zoomScale) / 2) + 'px' : 'center'} ${zoomRotation == 90 || zoomRotation == -270 ? ((containerWidth * zoomScale) / 2) + 'px' : 'center'}`,
                        paddingLeft: leftPadding,
                        overflow: 'auto'
                    }}>
                    <Stack
                        direction='row'
                        spacing={0}
                        justifyContent='center'
                    >
                        <div style={{
                            width: (sectionTwoHasPage || isSinglePageMode ? containerWidth : (containerWidth * 2)) * zoomScale,
                            height: containerHeight * zoomScale,
                            display: !hasCover && currentPage == 0 ? 'none' : null
                        }}>
                            <PdfView
                                partNumber={partNumber}
                                sectionFileName={currentSection?.fileName}
                                leftPageNumber={currentPage - currentPageOffset}
                                rightPageNumber={sectionOneHasNextPage ? currentPage - currentPageOffset + 1 : null}
                                documentWidth={pageCalculatedWidth}
                                documentHeight={pageCalculatedHeight}
                                offset={currentPageOffset}
                                isOversize={currentSection?.overSizeFile}
                            />
                        </div>
                        {sectionTwoHasPage ? (
                            <div style={{
                                width: containerWidth * zoomScale,
                                height: containerHeight * zoomScale
                            }}>
                                <PdfView
                                    partNumber={partNumber}
                                    sectionFileName={nextSection?.fileName}
                                    rightPageNumber={1}
                                    documentWidth={pageCalculatedWidth}
                                    documentHeight={pageCalculatedHeight}
                                    offset={nextPageOffset}
                                    isOversize={nextSection?.overSizeFile}
                                />
                            </div>
                        ) : null}
                    </Stack>
                </div>
            </div>
        </Dialog>
    )
}

const Transition = React.forwardRef(function Transition(
    props, ref,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const calculateDocumentPaneSize = (
    isHorizontal, isSinglePageMode, viewportWidth, viewportHeight, pageRatio
) => {
    let uiHeights = 120;
    if (!isSinglePageMode) {
        pageRatio = 1.3;
    }

    if (!isHorizontal) {
        const maxAllowedWidth = (isSinglePageMode ? viewportWidth : viewportWidth / 2) * .9;

        let startingHeight = viewportHeight - uiHeights;
        let startingWidth = startingHeight / pageRatio;

        while (startingWidth > maxAllowedWidth) {
            startingHeight = startingHeight * .95;
            startingWidth = startingHeight / pageRatio;
        }

        return { documentHeight: startingHeight / pageRatio, documentWidth: startingHeight };
    } else {
        const maxAllowedWidth = (isSinglePageMode ? viewportWidth : viewportWidth) * .9;

        let startingHeight = viewportHeight - uiHeights;
        let startingWidth = startingHeight * pageRatio;

        while (startingWidth > maxAllowedWidth) {
            startingHeight = startingHeight * .95;
            startingWidth = startingHeight * pageRatio;
        }

        return { documentHeight: startingHeight, documentWidth: startingHeight * pageRatio };
    }
}
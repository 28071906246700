import React from "react";
import ContentContainer from "./contentContainer";
import { Box, Grid } from "@mui/material";
import PageTitle from "./pageTitle";
import './g3SupportToolbox.css';
import DownloadLink from "./downloadLink";

export default function G3SupportToolbox() {
    return (
        <ContentContainer>
            <PageTitle title='Support' />

            <Box component="main" sx={{ p: 3, width: '100%' }}>
                <Grid container className="support-toolbox">
                    <Grid item xs={6}>
                        <h3>G3 Support</h3>
                        <ul>
                            <li>
                                <DownloadLink fileName='kvaser_drivers_setup.exe' label='SmartCraft Interface' />
                            </li>
                            <li>
                                <DownloadLink fileName='G3ApiAccessTroubleshooterV2.zip' label='Troubleshooter' />
                            </li>
                            <li>
                                <DownloadLink fileName='ndp48-web.exe' label='DotNet 4.8' />
                            </li>
                            <li>
                                <DownloadLink fileName='dotnet_cleanup_tool.zip' label='DotNet Cleanup' />
                            </li>
                            <li>
                                <DownloadLink fileName='G3_Remover.exe' label='G3 Remover' />
                            </li>
                            <li>
                                <DownloadLink fileName='PerfMonFix.zip' label='Perf Mon Fix' />
                            </li>
                            <li>
                                <DownloadLink fileName='G3 Serial Cable Driver.zip' label='Serial Cable Drivers' />
                            </li>
                            <li>
                                <DownloadLink fileName='FwUpdateTool_4_31.zip' label='Firmware' />
                            </li>
                            <li>
                                <DownloadLink fileName='aspnetcore-runtime-8.0.4-win-x86.exe' label='ASP Fix' />
                            </li>
                            <li>
                                <DownloadLink fileName='SyncTestV3.zip' label='Sync Test' />
                            </li>
                        </ul>
                    </Grid>
                </Grid>
            </Box>
        </ContentContainer>
    );
}
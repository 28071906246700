import { useWindowDimensions } from "../../../hooks";

export default function useDocumentViewMenuLabels(showingZoom) {
    const { width: viewportWidth } = useWindowDimensions();
    let tocTextLabel = 'Table of Contents';
    let singlePageViewLabel = 'Single Page View';
    let twoPageViewLabel = 'Side By Side View'
    let fullScreenLabel = 'Full Screen';
    let exitFullScreenLabel = 'Exit Full Screen';
    let expandViewLabel = 'Expand View';
    let fitToViewLabel = 'Fit To View';
    let searchLabel = 'Search';
    let zoomLabel = 'Zoom';
    let closeZoomLabel = 'Close'
    let rotateLeftLabel = 'Rotate Left';
    let rotateRightLabel = 'Rotate Right';
    let zoomInLabel = 'Zoom In';
    let zoomOutLabel = 'Zoom Out';
    if (!showingZoom) {
        if (viewportWidth < 1290) {
            tocTextLabel = 'TOC';
            singlePageViewLabel = 'Single Page';
            twoPageViewLabel = 'Side By Side';
        }

        if (viewportWidth < 1130) {
            fullScreenLabel = null;
            exitFullScreenLabel = null;
        }

        if (viewportWidth < 1070) {
            expandViewLabel = null;
            fitToViewLabel = null;
        }

        if (viewportWidth < 1000) {
            singlePageViewLabel = null;
            twoPageViewLabel = null;
        }

        if (viewportWidth < 950) {
            singlePageViewLabel = null;
            twoPageViewLabel = null;
        }

        if (viewportWidth < 940) {
            searchLabel = null;
        }

        if (viewportWidth < 910) {
            zoomLabel = null;
            tocTextLabel = null;
        }
    }
    else {
        if (viewportWidth < 1290) {
            fullScreenLabel = null;
        }

        if(viewportWidth < 1120) {
            rotateLeftLabel = 'Left';
            rotateRightLabel = 'Right';
        }

        if(viewportWidth < 1020) {
            rotateLeftLabel = null;
            rotateRightLabel = null;
            zoomInLabel = null;
            zoomOutLabel = null;
        }

        if(viewportWidth < 910) {
            closeZoomLabel = null;
        }
    }

    return {
        tocTextLabel,
        singlePageViewLabel,
        twoPageViewLabel,
        fullScreenLabel,
        exitFullScreenLabel,
        expandViewLabel,
        fitToViewLabel,
        searchLabel,
        zoomLabel,
        closeZoomLabel,
        rotateLeftLabel,
        rotateRightLabel,
        zoomInLabel,
        zoomOutLabel
    }
}
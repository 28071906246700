import React from 'react';
import styled from '@emotion/styled';

export default function DocumentTableOfContentsPage({ documentWidth, documentHeight, outline, setPageNumber, pageRatio }) {
    if (!outline) {
        return null;
    }

    const scale = documentWidth / 612;
    return (
        <div style={{ width: 612, height: 612 * pageRatio, overflow: 'hidden', transform: `scale(${scale})`, transformOrigin: '0 0' }}>
            <TocContainer
                documentWidth={documentWidth}
                documentHeight={documentHeight}
            >
                <TocSubContainer
                    documentWidth={documentWidth}
                    documentHeight={documentHeight}
                >
                    <TocHeaderFooter top='1.4em' />
                    <TocHeaderFooter bottom='1.9em' />
                    <TocTitle>Manual Outline</TocTitle>
                    <TocOutlineColumn>
                        {outline.children.map((section, index) => {
                            const { title, isFrontMatter, pageNumber, sectionNumber } = section;
                            const children = section.children || []

                            if (isFrontMatter || !pageNumber) {
                                return null;
                            }

                            return (
                                <TocOutlineSection key={index}>
                                    <TocOutlineSectionTitle onClick={() => setPageNumber(pageNumber - 1)}>
                                        {title}
                                    </TocOutlineSectionTitle>

                                    {!!sectionNumber ? children.map((subSection, subIndex) => {
                                        const { title: subSectionTitle, pageNumber: subSectionPageNumber } = subSection;
                                        return (
                                            <TocOutlineSubSectionTitle
                                                key={`${index}-${subIndex}`}
                                                onClick={() => setPageNumber(subSectionPageNumber - 1)}
                                            >
                                                {subSectionTitle}
                                            </TocOutlineSubSectionTitle>
                                        )
                                    }) : null}
                                </TocOutlineSection>
                            );
                        })}
                    </TocOutlineColumn>
                </TocSubContainer>
            </TocContainer>
        </div>
    );
}

const TocContainer = styled('div')(({ theme, documentWidth, documentHeight }) => {
    const scale = documentWidth / 612;
    return ({
        background: theme.palette.background.paper,
        height: '100%',
        width: '100%'
    });
})

const TocSubContainer = styled('div')(({ theme, documentWidth, documentHeight }) => {
    const scale = documentWidth / 612;
    return ({
        height: '100%',
        width: '100%',
        position: 'absolute'
    });
})

const TocHeaderFooter = styled('hr')(({ theme, top, bottom }) => {
    return ({
        borderTop: '1px solid',
        borderTopColor: theme.palette.primary.main,
        position: 'absolute',
        top: top,
        bottom: bottom,
        width: 'calc(100% - 5rem)',
        marginLeft: theme.documentView.pageMargin
    });
})

const TocTitle = styled('div')(({ theme }) => {
    return ({
        marginLeft: theme.documentView.pageMargin,
        marginTop: theme.documentView.pageMargin,
        marginBottom: '0.8rem',
        fontSize: theme.documentView.pageTitleFontSize,
        fontWeight: theme.documentView.pageTitleFontWeight,
        fontFamily: theme.documentView.fontFamily
    });
})

const TocOutlineColumn = styled('div')(({ theme }) => {
    return ({
        display: "flex",
        flexFlow: "column wrap",
        gap: "0 1rem",
        width: '100%',
        height: 'calc(100% - 7.75rem)',
        padding: `0 ${theme.documentView.pageMargin}`
    });
})

const TocOutlineSection = styled('div')(({ theme }) => {
    return ({
        maxWidth: 'calc(50% - 0.5rem)'
    });
})

const TocOutlineSectionTitle = styled('div')(({ theme }) => {
    return ({
        cursor: 'pointer',
        fontSize: theme.documentView.fontSize,
        fontFamily: theme.documentView.fontFamily,
    });
})

const TocOutlineSubSectionTitle = styled('div')(({ theme }) => {
    return ({
        cursor: 'pointer',
        marginLeft: '1rem',
        fontSize: theme.documentView.fontSize,
        fontFamily: theme.documentView.fontFamily
    });
})

import {
    Button, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, FormControl, TextField
} from "@mui/material";
import React, { useState } from "react";
import { logFeatureUsage } from "../../fetch/insightsHelper";

export default function JumpToPageDialog({ open, onClose, onNavigate, pageMappings, hasCover, pageCount }) {
    const [pageNumber, setPageNumber] = useState('');

    const pageMappingsMatches = pageMappings.filter(it => it.pageNumberText === pageNumber.toUpperCase());

    const isValidPageNumber = !!pageNumber && (
        (isInt(pageNumber) && pageNumber >= 1 && pageNumber <= pageCount)
        ||
        (hasCover && pageNumber.toLowerCase() === 'cover')
        ||
        (hasCover && pageNumber.toLowerCase() === 'toc')
        ||
        pageMappingsMatches.length > 0
    );

    const handleNavigate = () => {
        if (!isValidPageNumber) {
            return;
        }

        logFeatureUsage('JumpToPageNav');

        if (isInt(pageNumber)) {
            onNavigate(pageNumber);
        } else if (hasCover && pageNumber.toLowerCase() === 'cover') {
            logFeatureUsage('JumpToCoverNav');
            onNavigate(-1);
        } else if (hasCover && pageNumber.toLowerCase() === 'toc') {
            logFeatureUsage('JumpToTocNav');
            onNavigate(0);
        } else {
            logFeatureUsage('JumpToPrintedPageNumberNav');
            onNavigate(pageMappingsMatches[0].pageNumber);
        }

        onClose();
        setPageNumber('');
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="jump-dialog-title"
            aria-describedby="jump-dialog-description"
        >
            <DialogTitle id="jump-dialog-title">
                Jump to Page
            </DialogTitle>
            <DialogContent id="jump-dialog-description">
                <DialogContentText >
                    Jump to page number:
                </DialogContentText>
                <FormControl fullWidth style={{ marginTop: '1em' }}>
                    <TextField
                        label='Page'
                        value={pageNumber}
                        onChange={(event) => setPageNumber(event.target.value)}
                        onKeyDown={(event) => {
                            if (event.key == 'Enter' && isValidPageNumber) {
                                handleNavigate();
                                // Not sure why this will only work on a delay
                                setTimeout(() => onClose(), 250);
                            }
                        }}
                        autoFocus
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button disabled={!isValidPageNumber} onClick={handleNavigate}>
                    Go
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function isInt(value) {
    return !isNaN(value) &&
        parseInt(Number(value)) == value &&
        !isNaN(parseInt(value, 10));
}
import react from 'react';
import { Grid } from '@mui/material';

export default function Footer() {
    return (
        <footer>
            <div style={{ position: 'absolute', bottom: 0, width: '100%', height: '5em', backgroundColor: '#000', padding: '5px 0 5px 0', color: '#fff' }}>
                <Grid container>
                    <Grid item xs={12} style={{ fontSize: '12px' }}>
                        <p style={{ marginLeft: '5em' }}>
                            © {new Date().getFullYear()} Mercury Marine. All Rights Reserved.
                            <a href="https://www.brunswick.com/terms-of-use/" target="_blank"
                                style={{ color: '#fff', paddingLeft: '10px', textDecoration: 'underline', borderBottom: 'none' }}>
                                Terms &amp; Conditions
                            </a>
                            <a href="https://www.brunswick.com/privacy-policy/" target="_blank"
                                style={{ color: '#fff', paddingLeft: '10px', textDecoration: 'underline', borderBottom: 'none' }}>
                                Privacy Policy
                            </a>
                            <a href="https://rs.brunswick.com/"
                                style={{ color: '#fff', paddingLeft: '10px', textDecoration: 'underline', borderBottom: 'none' }}>
                                Remote
                            </a>
                        </p>
                        <p style={{ marginLeft: '5em' }}>
                            Reproduction in whole or in part without permission is prohibited. Mercury Marine is a division of Brunswick Corporation.
                        </p>
                    </Grid>
                </Grid>
            </div>
        </footer>
    );
}
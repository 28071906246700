export function capitalizeFirstLetter(string) { 
    return string.charAt(0).toUpperCase() + string.slice(1); 
} 
 
export const isPageNumberShowing = (currentPage, pageRange, hasCover, isSinglePageMode) => { 
    const adjustedCurrentPage = hasCover ? currentPage + 1 : currentPage; 
    const nextPage = !isSinglePageMode ? adjustedCurrentPage + 1 : adjustedCurrentPage; 
    return (pageRange.minimumPageNumber <= adjustedCurrentPage && pageRange.maximumPageNumber >= adjustedCurrentPage) || 
        (pageRange.minimumPageNumber <= nextPage && pageRange.maximumPageNumber >= nextPage); 
} 

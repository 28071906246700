import { Box } from '@mui/material';
import React from 'react';
import Spinner from '../spinner/Spinner';

export default function PageSpinner({ documentHeight }) {
    return (
        <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            minHeight={documentHeight}
        >
            <Spinner />
        </Box>
    )
}
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { parameterHelper } from '../../../helpers/parameterHelper';
import { Box, Grid, Stack } from '@mui/material';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import {
  RelatedDocumentsMenu, DocumentTableOfContents, DocumentSearch,
  DocumentViewMenu, calculateDocumentPaneSize
} from '../../documentViewCore';
import { useWindowDimensions, useIsSinglePage } from '../../../hooks';

import CoverViewPage from '../CoverPage/CoverPageView';
import DocumentPageView from '../DocumentPage/DocumentPageView';
import ZoomDialog from '../ZoomDialog';

const defaultParameters = { page: -1 };
export default function HasCoverDocumentView({ partNumber, metadata, pageRatio }) {
  const { page } = parameterHelper.getUrlParameters(defaultParameters);
  const { height: viewportHeight, width: viewportWidth } = useWindowDimensions();
  const location = useLocation();
  const navigate = useNavigate();

  const [documentDimensions, setDocumentDimensions] = useState({ documentHeight: viewportHeight, documentWidth: viewportHeight / 1.3 });

  const [showToc, setShowToc] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showZoom, setShowZoom] = useState(false);
  const [zoomRotation, setZoomRotation] = useState(0);
  const [zoomScale, setZoomScale] = useState(1);

  const { isSinglePageMode, isExpandMode } = useIsSinglePage();

  const { pageCount, outline, sections, pages } = metadata;

  useEffect(() => {
    setDocumentDimensions(calculateDocumentPaneSize(
      showToc || showSearch,
      isSinglePageMode,
      isExpandMode,
      viewportWidth,
      viewportHeight,
      pageRatio));
  }, [viewportHeight, viewportWidth, showToc, showSearch, isExpandMode, isSinglePageMode, pageRatio]);

  const currentPage = page;
  const nextPage = currentPage + 1;

  const currentSection = sections?.filter(it => it.start <= currentPage && it.end >= currentPage)[0];
  const currentPageOffset = currentSection?.start - 1;
  const isNextPageInCurrentSection = currentSection?.start <= nextPage && currentSection?.end >= nextPage

  const nextSection = sections?.filter(it => it.start <= nextPage && it.end >= nextPage)[0];
  const nextPageOffset = nextSection?.start - 1;

  const setPageNumber = (pageNumber) => {
    const isLeftPage = pageNumber % 2 > 0;
    const params = new URLSearchParams(location.search);
    params.set('page', isLeftPage || isSinglePageMode ? pageNumber : pageNumber - 1);

    if (pageNumber == (defaultParameters.page)) {
      params.delete('page');
    }

    navigate(`${location.pathname}?${params.toString()}`);
  }

  useEffect(() => {
    // Reset to odd page if we swich out of single page mode
    if (!isSinglePageMode && currentPage % 2 == 0) {
      setPageNumber(currentPage);
    }
  }, [isSinglePageMode, currentPage]);

  return (
    <Box component="main" sx={{ p: 0, overflow: 'hidden', maxHeight: '100%', width: '100%', marginTop: '8px' }}>
      <RelatedDocumentsMenu partNumber={partNumber} />
      <Grid container>
        <Grid item xs={12} xl={12}>
          <Stack direction='row' spacing={2} justifyContent='center'>
            {showToc ? (
              <DocumentTableOfContents
                outline={outline}
                onPageClick={setPageNumber}
                currentPage={currentPage}
                hasCover
                onCloseClick={() => setShowToc(false)}
              />
            ) : null}
            {showSearch ? (
              <DocumentSearch
                onPageClick={setPageNumber}
                currentPage={currentPage}
                partNumber={partNumber}
                onCloseClick={() => setShowSearch(false)}
              />
            ) : null}
            {currentPage > 0 ? (
              <DocumentPageView
                hasLeftPage={true} //TODO
                hasRightPage={true} // TODO
                currentPage={currentPage}
                hasCover
                documentDimensions={documentDimensions}
                partNumber={partNumber}
                currentPageOffset={currentPageOffset}
                nextPageOffset={nextPageOffset}
                isNextPageInCurrentSection={isNextPageInCurrentSection}
                currentSection={currentSection}
                nextSection={nextSection}
                setPageNumber={setPageNumber}
                pageCount={pageCount}
              />
            ) : (
              <CoverViewPage
                currentPage={currentPage}
                documentDimensions={documentDimensions}
                partNumber={partNumber}
                outline={outline}
                setPageNumber={setPageNumber}
                pageRatio={pageRatio}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
      <DocumentViewMenu
        setPageNumber={setPageNumber}
        currentPage={currentPage}
        hasCover
        pageCount={pageCount}
        showToc={!!outline}
        onToggleToc={() => {
          setShowToc(!showToc);
          // if it was not show toc that means it's now show search so dimiss search so we can use the same space
          if (!showToc) {
            setShowSearch(false);
          }
        }}
        onToggleSearch={() => {
          setShowSearch(!showSearch);
          // if it was not show search that means it's now show search so dimiss toc so we can use the same space
          if (!showSearch) {
            setShowToc(false);
          }
        }}
        showingZoom={showZoom}

        onClickZoom={() => setShowZoom(!showZoom)}
        zoomRotation={zoomRotation}
        setZoomRotation={setZoomRotation}
        zoomScale={zoomScale}
        setZoomScale={setZoomScale}

        partNumber={partNumber}
        hasMultipleSections={sections?.length > 1}
        currentSection={currentSection}
        nextSection={nextSection}
        pageMappings={pages}
      />
      <ZoomDialog
        open={showZoom}
        onClose={() => setShowZoom(false)}
        partNumber={partNumber}
        currentSection={currentSection}
        currentPage={currentPage}
        currentPageOffset={currentPageOffset}
        zoomRotation={zoomRotation}
        zoomScale={zoomScale}
        isSinglePageMode={isSinglePageMode}
        sectionOneHasNextPage={isNextPageInCurrentSection && !isSinglePageMode}
        pageRatio={pageRatio}
        nextSection={nextSection}
        nextPageOffset={nextPageOffset}
        hasCover
      />
    </Box >
  );
}
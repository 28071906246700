import React, { useRef, useState } from 'react';
import { saveAs } from 'file-saver'
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { getAuthenticatedHeaders } from '../../fetch/fetchHeaders';
import Spinner from '../spinner/Spinner';
import { styled } from '@mui/material';
import { logDocumentDownload, logDownloadFailed } from '../../fetch/insightsHelper';
import { useSelector } from 'react-redux';
import { useNotification } from '../../hooks/useNotification';

const MessageContainer = styled('div')(({ theme, offsetLeft, offsetTop }) => ({
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    color: 'black',
    position: 'absolute',
    width: '11.5em',
    height: '4em',
    top: offsetTop,
    left: offsetLeft,
}));

export default function DownloadPdfButton() {
    const { partNumber } = useParams();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const downloadButtonRef = useRef(null);
    const userLanguage = useSelector((state) => state.permissions.language);
    const canDownload = useSelector((state) => state.permissions.canDownload);
    const { showErrorModal } = useNotification();

    if (!canDownload) {
        return null;
    }

    const handleFullDownload = () => {
        if (drawerOpen) {
            return;
        }

        setDrawerOpen(true);
        getAuthenticatedHeaders().then((headers) => {
            return fetch(`/document/pdf/${partNumber}.pdf`, { headers })
                .then(response => {
                    return new Promise(function (resolve, reject) {
                        if (response.status >= 400) {
                            reject(response);
                            return
                        }

                        resolve(response);
                        return;
                    });
                })
                .then(response => response.blob())
                .then(blob => saveAs(blob, `${partNumber}.pdf`))
                .then(() => logDocumentDownload(partNumber, userLanguage), handleFullDownloadError)
                .finally(() => setDrawerOpen(false));
        });
    }

    const handleFullDownloadError = (error) => {
        console.log('error downloading', error);
        logDownloadFailed(partNumber);
        showErrorModal('An error occurred generating a file for download.')
    }

    return (
        <>
            {drawerOpen ? (
                <MessageContainer
                    offsetLeft={downloadButtonRef?.current?.offsetLeft - 25}
                    offsetTop={downloadButtonRef?.current?.offsetTop * 2.5}
                >
                    <div style={{ padding: '0.6em 0.5em', float: 'left' }}>
                        Generating PDF
                    </div>
                    <div style={{ padding: '0.6em 0.5em', float: 'right' }}><Spinner displayInline width={20} /></div>
                    <div style={{ clear: 'both' }}></div>
                </MessageContainer>
            ) : null}
            <Button
                color="inherit"
                title='Download'
                onClick={() => handleFullDownload()}
                ref={downloadButtonRef}
            >
                <FileDownloadIcon />
                Download
            </Button>
        </>
    );
}
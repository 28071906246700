import { useDispatch, useSelector } from "react-redux";
import { setExpandMode } from "../redux/documentViewSlice";

export default function useScrollViewZoomScale(defaultZoomScale, maxZoomScale) {
    const dispatch = useDispatch();

    const isExpandMode = useSelector((state) => state.documentView.isExpandMode) || false;

    const zoomScale = isExpandMode ? maxZoomScale : defaultZoomScale;
    const toggleExpandMode = (newZoomScale) => {
        dispatch(setExpandMode(newZoomScale > defaultZoomScale ? true : false));
    }

    return [zoomScale, toggleExpandMode];
}
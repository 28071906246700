import { msalInstance } from "..";
import { loginRequest } from "../helpers/authConfig";

export async function getAuthenticatedHeaders(headers, resolveWhenNotAuthenticated) {
    headers = headers || {};

    const account = msalInstance.getActiveAccount();
    if (!account && resolveWhenNotAuthenticated) {
        return await Promise.resolve(headers);
    }

    try {
        const response = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account
        });
        headers = { ...headers, 'Authorization': 'Bearer ' + response.idToken };
    }
    catch (exception) {
        msalInstance.loginRedirect(loginRequest);
    }

    return await Promise.resolve(headers);
}
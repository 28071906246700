import React, { useEffect, useRef } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, ListItem, TextField } from '@mui/material';

export default function SearchListItem({ text, workingQuery, onWorkingQueryChanged, onExecutedQuery }) {
    const searchInput = useRef(null);

    useEffect(() => {
        if (!!searchInput && !!searchInput.current) {
            searchInput.current.focus()
        }
    }, [])

    return (
        <ListItem
            secondaryAction={
                <IconButton
                    onClick={onExecutedQuery}
                >
                    <SearchIcon color='primary' />
                </IconButton>
            }>
            <TextField
                label={text}
                value={workingQuery}
                onChange={(event) => onWorkingQueryChanged(event.target.value)}
                onKeyDown={(event) => {
                    if (event.key == 'Enter') {
                        onExecutedQuery();
                    }
                }}
                sx={{ width: '95%' }}
                inputRef={searchInput}
            />
        </ListItem>
    )
}
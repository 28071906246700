import React from "react";
import { forwardRef } from "react";

const DocumentScrollViewer = forwardRef(function DocumentScrollViewer({ width, children, onScroll }, ref) {
    return (
        <div
            onScroll={onScroll}
            style={{
                overflowY: 'auto',
                overflowX: 'hidden',
                height: 'calc(100vh - 129px)',
                width: width
            }}
            ref={ref}
        >
            {children}
        </div>
    );
})

export default DocumentScrollViewer;
import useSWR from 'swr';
import { getAuthenticatedHeaders } from './fetchHeaders';

export const useDocumentMetadata = (partNumber) => {
    const { data, error, isValidating, isLoading, mutate } = useSWR(
        getPath(partNumber),
        fetcher,
        swrConfig);

    let localError = error;
    if (data?.status >= 400 && !error) {
        localError = data;
    }

    const metadata = data || { pageCount: 0 };

    return { metadata, error: localError, isValidating, isLoading, mutate };
};

export const useVideoMetadata = (partNumber) => {
    const { data, error, isValidating, isLoading, mutate } = useSWR(
        !!partNumber ? `/document/${partNumber}/videoMetadata` : null,
        fetcher,
        swrConfig);

    let localError = error;
    if (data?.status >= 400 && !error) {
        localError = data;
    }

    const metadata = data || { pageCount: 0 };

    return { metadata, error: localError, isValidating, isLoading, mutate };
};

const getPath = (partNumber) => {
    if (!partNumber) {
        return null;
    }

    return `/document/${partNumber}/metadata`;
}

export const useRelatedDocuments = (partNumber) => {
    const { data, error, isValidating, isLoading, mutate } = useSWR(
        getRelatedPath(partNumber),
        fetcher,
        swrConfig);

    const related = data || [];

    return { related, error, isValidating, isLoading, mutate };
};

const getRelatedPath = (partNumber) => {
    if (!partNumber) {
        return null;
    }

    return `/document/${partNumber}/metadata/related`;
}

export const useDocumentMetadataSearch = (partNumber, query) => {
    const { data, error, isValidating, isLoading, mutate } = useSWR(
        getSearchPath(partNumber, query),
        fetcher,
        swrConfig);

    const items = data || [];

    return { items, error, isValidating, isLoading, mutate };
};

const getSearchPath = (partNumber, query) => {
    if (!partNumber || !query) {
        return null;
    }

    return `/document/${partNumber}/search?query=${query}`;
}

const fetcher = (url) => {
    return getAuthenticatedHeaders().then((headers) => {
        return fetch(url, { headers }).then(response => response.json())
    });
}

const swrConfig = {
    revalidateOnFocus: false,
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Never retry on 4XX error.
        if (error.status >= 400 && error.status < 500) return;

        //   // Never retry for a specific key.
        //   if (key === '/api/user') return

        // Only retry up to 1 times.
        if (retryCount > 1) return;

        // Retry after 1 seconds.
        setTimeout(() => { revalidate({ retryCount }); }, 1000)
    }
};
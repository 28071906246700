import { useEffect, useState, useMemo } from 'react';

export default function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);
    const [intersectionRatio, setIntersectionRatio] = useState(0);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                setIsIntersecting(entry.isIntersecting);
                setIntersectionRatio(entry.intersectionRatio);
            },
                { threshold: [0.01, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] }),
        [],
    );

    useEffect(() => {
        if (!!ref?.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);

    return [isIntersecting, intersectionRatio];
}
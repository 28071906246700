import { Box, Grid } from "@mui/material";
import React from "react";
import ContentContainer from "./contentContainer";
import './servicePortalHome.css';

export default function ServicePortalHome() {
    return (
        <ContentContainer>
            <Box component="main" sx={{ p: 3, width: '100%' }}>
                <Grid container>
                    <Grid item xs={12}>
                        <div style={{ height: '1px', backgroundColor: '#777777' }}>&nbsp;</div>
                    </Grid>
                </Grid>
            </Box >

            <Box component="main" sx={{ p: 3, width: '100%' }}>
                <Grid container className="service-portal-home">
                    <Grid md={6} item>
                        <h1>CDS G3<br />&nbsp;is available now!</h1>
                        <p>Keep your diagnostic software up to date and order CDS G3 today.</p>
                        <p>CDS G3 is delivered via a download. A license key is required to unlock the software and only contracted Mercury Marine dealers are authorized to use the software. The license key can be purchased from Mercury Marine.&nbsp; <br /> <br /> <a href="/g3/support/system-requirements">System Requirements</a></p>
                        <p>Existing G3 2022 license keys expire May 15th, and existing G3 2023 license keys expire Jun 15th.</p>
                    </Grid>

                    <Grid md={6} item>
                        <div style={{ textAlign: 'center' }}>
                            <img src="diskcover.jpg" alt="" style={{ width: '400px', height: '398px' }} />
                        </div>
                    </Grid>
                </Grid>
            </Box >

            <Box component="main" sx={{ p: 3, width: '100%' }}>
                <Grid container>
                    <Grid item xs={12}>
                        <div style={{ height: '1px', backgroundColor: '#777777' }}>&nbsp;</div>
                    </Grid>
                </Grid>
            </Box >

            <Box component="main" sx={{ p: 3, width: '100%' }}>
                <Grid container>
                    <Grid md={12} item>
                        <h2>Computer Diagnostic System G3</h2>
                        <p>CDS G3 is a standalone PC based program that complements the original Mercury Computer Diagnostic System (CDS) by providing diagnostic support for select engines and Mercury Joystick Piloting systems. Additionally, all configuration functions necessary for preparing these systems for delivery are also supported. CDS G3 allows for CAN based multiple processor communication through a clean, easy to navigate interface.<br /> <br /> </p>
                    </Grid>
                </Grid>
            </Box >
        </ContentContainer>
    );
}
import { createSlice } from '@reduxjs/toolkit'
import { logFilterUsed } from '../fetch/insightsHelper';

export const defaultFiltersState = {
    query: '',
    families: [],
    type: '',
    group: [],
    searchTextBoxValue: '',
    language: '',
    state: 3,
    year: ''
}

export const libraryFiltersSlice = createSlice({
    name: 'libraryFilters',
    initialState: {
        ...defaultFiltersState
    },
    reducers: {
        executeQuery: (state, action) => {
            state.query = action.payload.trim();
            state.searchTextBoxValue = state.query;
            logFilterUsed('query', state.query);
        },
        clearQuery: (state) => {
            state.query = '';
            state.searchTextBoxValue = state.query;
        },
        changeFilter: (state, action) => {
            const { fieldName, value } = action.payload;
            if (state[fieldName] != value && fieldName != 'searchTextBoxValue') {
                logFilterUsed(fieldName, value);
            }
            state[fieldName] = value;
        },
        clearFilters: (state) => {
            state.query = defaultFiltersState.query;
            state.families = defaultFiltersState.families;
            state.type = defaultFiltersState.type;
            state.group = defaultFiltersState.group;
            state.searchTextBoxValue = defaultFiltersState.searchTextBoxValue;
            state.language = defaultFiltersState.language;
            state.state = defaultFiltersState.state;
            state.year = defaultFiltersState.year;
        }
    }
})

// Action creators are generated for each case reducer function
export const { changeFilter, clearFilters, executeQuery, clearQuery } = libraryFiltersSlice.actions

export default libraryFiltersSlice.reducer
import React, { useState } from "react";
import ContentContainer from "./contentContainer";
import { Box, Grid, InputLabel, TextField } from "@mui/material";
import PageTitle from "./pageTitle";
import { apiHostUrl } from "../../helpers";
import { useDealerExistsAnon } from "../../fetch/userFetch";
import Spinner from "../spinner/Spinner";
import { useIsLicenseKeyValidForDieselDownload } from "../../fetch/licenseKeyFetch";

const isKeyValid = (licenseKey) => {
    return /^([A-Za-z0-9]{5}-){4}[A-Za-z0-9]{5}$/.test(licenseKey.replaceAll(/\_/g, ''));
}

export default function DieselDownload() {
    const [state, setState] = useState({
        dealerNumber: '',
        licenseKey: ''
    });

    const { dealerNumber, licenseKey } = state;
    const keyIsValid = isKeyValid(licenseKey);

    const { exists: dealerValidationValue, isLoading: dealerIsValidating } = useDealerExistsAnon(dealerNumber);
    const isDealerValid = !!dealerValidationValue;

    const { data: licenseKeyStatus, isLoading: licenseKeyIsValidating } = useIsLicenseKeyValidForDieselDownload(dealerNumber, licenseKey, isDealerValid);
    let validated = false,
        installerFilename = null,
        isValid = false;
    if (!!licenseKeyStatus) {
        validated = true;
        if (licenseKeyStatus.status == 4) {
            isValid = true;
            installerFilename = licenseKeyStatus.installerFilename
        }
    }
    const downloadUrl = `${apiHostUrl}/api/v1/file/diesel/${licenseKey}/${dealerNumber}`;

    const handleDealerNumberChange = (event) => {
        const dealerNumber = event.target.value.replaceAll(/\//g, '');
        setState({ ...state, dealerNumber });
    }

    const handleLicenseKeyChange = (licenseKey) => {
        const inputLicenseKey = licenseKey;

        let outputLicenseKey = '';
        let segment = '';

        for (let i = 0; i < inputLicenseKey.length; i++) {
            const char = inputLicenseKey[i];

            if (/\w/.test(char) && char != '-') {
                segment += char;
            }

            if (segment.length == 5) {
                outputLicenseKey += segment;
                if (i !== 28) {
                    outputLicenseKey += '-';
                }
                segment = '';
            }

        }

        if (segment.length > 0) {
            outputLicenseKey += segment;
        }

        setState({ ...state, licenseKey: outputLicenseKey });
    }

    return (
        <ContentContainer>
            <PageTitle title='Download' />
            <Box component="main" sx={{ p: 3, width: '100%', marginTop: '-2em' }}>
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <InputLabel htmlFor='dealerNumber' style={{ padding: '0.5em 0' }}>Dealer Number</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            required
                            error={!isDealerValid && !!dealerNumber}
                            value={dealerNumber}
                            id='dealerNumber'
                            onChange={handleDealerNumberChange}
                            size="small"
                            inputProps={{ maxLength: 50 }}
                        />
                        {dealerIsValidating ? <Spinner displayInline width='2.1em' /> : null}
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={2}>
                        <InputLabel htmlFor='licenseKey' style={{ padding: '0.5em 0' }}>License Key</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            required
                            error={!!licenseKey && (!keyIsValid || !isValid)}
                            value={licenseKey}
                            helperText={validated && !isValid ? 'Dealer/License Key combination invalid' : null}
                            id='licenseKey'
                            onChange={(event) => handleLicenseKeyChange(event.target.value)}
                            size="small"
                            style={{ width: '20em' }}
                            inputProps={{ maxLength: 29 }}
                        />
                        {licenseKeyIsValidating ? <Spinner displayInline width='2.1em' /> : null}
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={10}>
                        {!!installerFilename ? <a className='MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium css-1y15k5x-MuiButtonBase-root-MuiButton-root' href={downloadUrl} download={installerFilename}>Download</a> : null}
                    </Grid>
                </Grid>
            </Box>
        </ContentContainer>
    )
}
import { Checkbox, ListItem, ListItemText } from "@mui/material";
import React from "react";

export default function ListItemCheckbox({ id, text, isChecked, onClick, isIndeterminate, marginLeft }) {
    return (
        <ListItem sx={{ pt: 0, pb: 0, ml: marginLeft }} onClick={onClick}>
            <Checkbox checked={isChecked} indeterminate={isIndeterminate} />
            <ListItemText id={id} primary={text} />
        </ListItem>
    );
}
import React from 'react';
import { Button } from '@mui/material';
import usePhoneMode from '../../../hooks/usePhoneMode';

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import './PdfPageButton.css';
import { logFeatureUsage } from '../../../fetch/insightsHelper';

export default function PdfPageButton({ direction, show, documentHeight, onClick }) {
    const { isMobile } = usePhoneMode();

    let marginLeft = null,
        marginRight = null;
    if(isMobile) {
        if(direction === 'previous'){
            marginLeft = '-32px';
            marginRight = '-32px';
        } else {
            marginLeft = '-16px';
            marginRight = '-32px';
        }
    }

    if (!show) {
        return null;
    }
    return (
        <Button
            title={direction === 'previous' ? 'Previous' : 'Next'}
            onClick={() => {
                onClick();
                logFeatureUsage('SidePageNav');
            }}
            style={{
                height: 'calc(100vh - 129px)',
                maxHeight: documentHeight,
                marginLeft: marginLeft,
                marginRight: marginRight
            }}
            className='pdf-page-button'
        >
            {direction === 'previous' ? <NavigateBeforeIcon /> : <NavigateNextIcon />}
        </Button>
    );
}
import React from "react";
import ContentContainer from "./contentContainer";
import { Box, Grid } from "@mui/material";

export default function PageTitle({ title }) {
    return (
        <Box component="main" sx={{ p: 3, width: '100%' }}>
            <Grid container>
                <Grid item xs={12} style={{ borderBottom: '1px solid #777', paddingBottom: '12px' }}>
                    <div style={{ color: '#43402f', fontSize: '34px', lineHeight: '44px', fontWeight: 400, textTransform: 'uppercase' }}>
                        {title}
                    </div>
                </Grid>
            </Grid>
        </Box >
    );
}
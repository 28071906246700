import { LogLevel } from "@azure/msal-browser";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: window.b2cConfig.clientId,
        authority: window.b2cConfig.authority,
        knownAuthorities: window.b2cConfig.knownAuthorities,
        validateAuthority: false,
        redirectUri: "/literature",
        postLogoutRedirectUri: "/literature/logout",
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: isIE || isEdge || isFirefox,
    },
    system: {
        allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                // if (containsPii) {
                //     return;
                // }
                // switch (level) {
                //     case LogLevel.Error:
                //         console.error(message);
                //         return;
                //     case LogLevel.Info:
                //         console.info(message);
                //         return;
                //     case LogLevel.Verbose:
                //         console.debug(message);
                //         return;
                //     case LogLevel.Warning:
                //         console.warn(message);
                //         return;
                //     default:
                //         return;
                // }
            },
        },
    },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: [
        ...window.b2cConfig.scopes,
        'openid',
        'profile',
        'email'
    ]
};
import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';

export default function LiteratureErrorPage({ status }) {
    return (
        <Box component="main" sx={{ p: 3, width: '100%', height: '100%' }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2 }}>
                        <Typography variant="h3">{status == 404 ? 'Not Found' : 'Unexpected Error'}</Typography>
                        {status == 404 ? (
                            <p>This page does not seem to exist, <a href="/literature">click here</a> to return to the home page.</p>
                        ) : (
                            <p>Sorry, an unexpected error has occurred.</p>
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}
import React, { useEffect, useState } from 'react';
import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid,
    TextField
} from '@mui/material';
import { msalInstance } from '../..';
import { useFetchProfile } from '../../fetch/userFetch';
import Spinner from '../spinner/Spinner';
import { getAuthenticatedHeaders } from '../../fetch/fetchHeaders';

export default function FeedbackModal({ open, onClose }) {
    const [isSaving, setSaving] = useState(false);
    const [error, setError] = useState(false);
    const [model, setModel] = useState({});
    const { name, email, accountNumber, feedbackMessage } = model;

    const { data: serverProfile } = useFetchProfile(open);

    useEffect(() => {
        if (open && !!serverProfile) {
            reset();
        }
    }, [open, serverProfile]);

    const reset = () => {
        let name = '';
        const account = msalInstance.getActiveAccount();
        if (!!account && !!account.name) {
            name = account.name;
        }
        setModel({ name, email: serverProfile?.email, accountNumber: serverProfile?.accountNumber });
        setSaving(false);
        setError(false);
    }

    const handleCancel = () => {
        reset();
        onClose();
    }

    const handleAccept = () => {
        setSaving(true);

        getAuthenticatedHeaders({ 'Content-Type': 'application/json' }).then((headers) => {
            fetch(`/user/feedback`,
                {
                    method: 'POST',
                    body: JSON.stringify(model),
                    headers
                })
                .then((response) => {
                    if (!response.ok) {
                        setError('Error submitting feedback');
                        setSaving(false);
                    } else {
                        reset();
                        onClose();
                    }
                }, (error) => {
                    setError('Error submitting feedback');
                    setSaving(false);
                }).finally(() => {
                    setSaving(false);
                });
        });
    }

    const handleValueChanged = (fieldName, value) => {
        const newModel = { ...model };
        newModel[fieldName] = value;
        setModel(newModel);
    }

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            fullWidth
        >
            <DialogTitle id="dialog-title">
                Feedback
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="dialog-description">
                    {isSaving ? (
                        <Grid container>
                            <Grid item md={12}>
                                <Spinner />
                            </Grid>
                        </Grid>
                    ) : null}
                    {!!error ? (
                        <Grid container>
                            <Grid item md={12}>
                                <p>
                                    Failed sending feedback
                                </p>
                            </Grid>
                        </Grid>
                    ) : null}
                    {!isSaving ? (
                        <Grid container>
                            <Grid item md={6} p={1}>
                                <TextField
                                    label="Name"
                                    variant="standard"
                                    value={name || ''}
                                    fullWidth
                                    onChange={(event) => handleValueChanged('name', event.target.value)}
                                />
                            </Grid>
                            <Grid item md={6} p={1}>
                                <TextField
                                    label="Email"
                                    variant="standard"
                                    value={email || ''}
                                    fullWidth
                                    onChange={(event) => handleValueChanged('email', event.target.value)}
                                />
                            </Grid>
                            <Grid item md={6} p={1}>
                                <TextField
                                    label="Account Number"
                                    variant="standard"
                                    value={accountNumber || ''}
                                    fullWidth
                                    onChange={(event) => handleValueChanged('accountNumber', event.target.value)}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    label="Feedback"
                                    variant="standard"
                                    value={feedbackMessage || ''}
                                    fullWidth
                                    onChange={(event) => handleValueChanged('feedbackMessage', event.target.value)}
                                    multiline
                                    rows={6}
                                />
                            </Grid>
                        </Grid>
                    ) : null}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button
                    onClick={handleAccept}
                    disabled={!name || !email || !accountNumber || !feedbackMessage || isSaving}
                >
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    );
}
import React, { useEffect, useRef } from "react";
import ImagePageContainer from "./ImagePageContainer";
import { useThumbImage } from "../../../fetch/fetchImage";
import { PageSpinner } from "../../documentViewCore";

export default function ScrollViewCoverPage({ partNumber, hasCover, documentDimensions, onToggleZoom }) {
    const { image, isLoading } = useThumbImage(hasCover ? partNumber : null);
    const ref = useRef(null);

    useEffect(() => {
        if (!!ref.current) {
            ref.current.id = `page=-1`
        }
    }, [ref?.current]);

    if (!hasCover) {
        return null;
    }

    return (
        <ImagePageContainer
            documentDimensions={documentDimensions}
            ref={ref}
            image={image}
            onDoubleClick={onToggleZoom}
        >
            {isLoading ? (
                <PageSpinner />
            ) : null}
        </ImagePageContainer>
    );
}
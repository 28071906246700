import React from "react";

import './Spinner.css';
import propeller from './loading-prop.svg'

export default function Spinner({ displayInline, width }) {
    return (
        <div className='propeller-spinner' style={{
            display: displayInline ? 'inline-block' : null
        }}>
            <img
                src={propeller}
                style={{
                    width: !!width ? width : null
                }} />
        </div>
    );
}
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import DropMenu from './DropMenu';
import usePhoneMode from '../../hooks/usePhoneMode';
import MenuIcon from '@mui/icons-material/Menu';
import LiteratureNavMenu from './LiteratureNavMenu';

export default function NavMenu() {
  const navigate = useNavigate();
  const { isMobile, isLandscape } = usePhoneMode();

  const { pathname } = useLocation();
  const isInLiteratureView = pathname.toLowerCase().indexOf('/literature') >= 0;

  if (isInLiteratureView) {
    return <LiteratureNavMenu />
  }
  else if (!isInLiteratureView && isMobile) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" style={{ background: 'black' }}>
          <Toolbar variant='regular' style={{ backgroundColor: '#2c2c2c', backgroundImage: 'url(header-strips.png)', backgroundRepeat: 'no-repeat', marginTop: '1em' }}>
            <Typography variant="h6" component="div" noWrap>
              {isLandscape ?
                (<img className='logo' src='logocombined.png' style={{ marginLeft: '6em', marginTop: '0.25em' }} />) :
                (<img className='logo' src='wavelogo.png' style={{ height: '51px', marginLeft: '6.5em', marginTop: '0.25em' }} />)
              }
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <DropMenu
              label={(<MenuIcon />)}
              subItems={[
                // { label: 'Literature', onClick: () => navigate('/literature') },
                // { divider: true },
                { label: 'Contact', onClick: () => navigate('/contact') },
                { divider: true },
                { label: 'G3 Components', onClick: () => navigate('/g3/components') },
                { label: 'G3 System Requirements', onClick: () => navigate('/g3/support/system-requirements') },
                { label: 'G3 Release Notes', onClick: () => navigate('/g3/support/release-notes') },
                { label: 'G3 Toolbox', onClick: () => navigate('/g3/support/toolbox') },
                { label: 'G3 Updates', onClick: () => navigate('/g3/support/updates') },
                { label: 'G3 Supported Products', onClick: () => navigate('/g3/support/products') },
                // { divider: true },
                // { label: 'G3 Download', onClick: () => navigate('/g3/download') },
                // { label: 'Diesel Download', onClick: () => navigate('/diesel/download') },
              ]}
            />
          </Toolbar>
        </AppBar>
      </Box>
    );
  }
  else {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" style={{ background: 'black' }}>
          <Toolbar variant='regular' style={{ backgroundColor: '#2c2c2c', backgroundImage: 'url(header-strips.png)', backgroundRepeat: 'no-repeat', marginTop: '1em' }}>
            <Typography variant="h6" component="div" noWrap>
              <img className='logo' src='logocombined.png' style={{ marginLeft: '6em', marginTop: '0.25em' }} />
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              onClick={() => navigate('/')}
              style={{ color: '#FFF' }}
            >
              Home
            </Button>
            <DropMenu
              label='Support'
              subItems={[
                { label: 'Components', onClick: () => navigate('/g3/components') },
                { label: 'System Requirements', onClick: () => navigate('/g3/support/system-requirements') },
                { label: 'Release Notes', onClick: () => navigate('/g3/support/release-notes') },
                { label: 'Toolbox', onClick: () => navigate('/g3/support/toolbox') },
                { label: 'Updates', onClick: () => navigate('/g3/support/updates') },
                { label: 'Supported Products', onClick: () => navigate('/g3/support/products') }
              ]}
            />
            <DropMenu
              label='Download'
              subItems={[
                { label: 'G3', onClick: () => navigate('/g3/download') },
                { label: 'Diesel', onClick: () => navigate('/diesel/download') }
              ]}
            />
            <Button
              onClick={() => navigate('/contact')}
              style={{ color: '#FFF' }}
            >
              Contact
            </Button>
            {/* <Button
              onClick={() => navigate('/literature')}
              style={{ color: '#FFF' }}
            >
              Literature
            </Button> */}
          </Toolbar>
        </AppBar>
      </Box>
    );
  }
}
import React from 'react';
import { useLocation, useRouteError } from 'react-router-dom';
import ServicePortalErrorPage from './ServicePortalErrorPage';
import LiteratureErrorPage from './LiteratureErrorPage';

export default function ErrorPage() {
    const { pathname } = useLocation();
    const isInLiteratureView = pathname.indexOf('/literature') >= 0;

    const { status, statusText, internal, error } = useRouteError();
    const { message, stack } = error || {};
    console.error(statusText, internal, message, stack);

    if (!isInLiteratureView) {
        return (
            <ServicePortalErrorPage status={status} />
        );
    }

    return (
        <LiteratureErrorPage status={status} />
    )
}
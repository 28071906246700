import React, { useEffect, useState } from 'react';
import { useIsAuthenticated } from "@azure/msal-react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useLocation } from 'react-router-dom';
import { msalInstance } from '..';
import moment from 'moment';
import { loginRequest } from "../helpers/authConfig";

export default function ReAuthDialog() {
    const [open, setOpen] = useState(false);
    const [lastCheck, setLastCheck] = useState(null);
    const isAuthenticated = useIsAuthenticated();

    const logout = () => {
        msalInstance.logoutRedirect();
    }
    const reAuthenticate = async () => {
        const account = msalInstance.getActiveAccount();
        await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account
        });
    };

    const { pathname } = useLocation();
    const isInLiteratureView = pathname.indexOf('/literature') >= 0 && pathname.indexOf('/logout') < 0;

    const handleReAuthenticate = () => {
        setOpen(false);
        reAuthenticate();
    };

    const handleSignout = () => {
        setOpen(false);
        logout();
    };

    const isAlmostExpired = () => {
        const account = msalInstance.getActiveAccount();
        if (!!account && !!account.idTokenClaims &&
            new Date(account.idTokenClaims.exp * 1000) > new Date()) {

            var exipringSoonTime = moment().add('10', 'm');
            var expireTimeMoment = moment(account.idTokenClaims.exp * 1000);
            //console.log(expireTimeMoment.diff(exipringSoonTime, 'minutes') + ' minutes till renew attempt');
            if (expireTimeMoment < exipringSoonTime) {
                return true
            }
        }

        return false;
    }

    const isExpired = () => {
        const account = msalInstance.getActiveAccount();
        if (!!account && !!account.idTokenClaims &&
            new Date(account.idTokenClaims.exp * 1000) > new Date()) {

            var currentTime = moment();
            var expireTimeMoment = moment(account.idTokenClaims.exp * 1000);
            //console.log(expireTimeMoment.diff(currentTime, 'minutes') + ' minutes till expiration');
            if (expireTimeMoment.diff(currentTime, 'minutes') > 0) {
                return false
            }
        }

        return true;
    }


    useEffect(() => {
        setTimeout(() => {
            if (isAuthenticated && isAlmostExpired() && isInLiteratureView) {
                setOpen(true);
            } else {
                setOpen(false);
            }
        }, 250);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (isExpired() && isInLiteratureView) {
                logout();
            }

            if (isAuthenticated && isAlmostExpired() && isInLiteratureView) {
                setOpen(true);
            } else {
                setOpen(false);
            }

            if (isAuthenticated) {
                setLastCheck(new Date());
            }
        }, 60000);
    }, [isAuthenticated, lastCheck]);

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Session Timeout
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Your session is about to timeout, do you want to continue using the application?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSignout}>Sign out</Button>
                <Button onClick={handleReAuthenticate} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
}
import { createSlice } from '@reduxjs/toolkit'

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        errorList: [],
        prompt: null
    },
    reducers: {
        showError: (state, action) => {
            if (state.errorList.indexOf(action.payload.message) < 0) {
                state.errorList = [...state.errorList, action.payload.message];
            }
        },
        dismissError: (state) => {
            const errorList = [...state.errorList];
            errorList.shift();
            state.errorList = errorList;
        },
        showPrompt: (state, action) => {
            state.prompt =  {
                title: action.payload.title,
                message: action.payload.message,
                onAccept: action.payload.onAccept,
                onCancel: action.payload.onCancel,
                zIndex: action.payload.zIndex
            };
        },
        dismissPrompt: (state) => {
            state.prompt = null;
        }
    },
})

export const { showError, dismissError, showPrompt, dismissPrompt } = notificationsSlice.actions

export default notificationsSlice.reducer
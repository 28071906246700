import { Grid, Typography } from "@mui/material";
import React from "react";

export default function ServiceBulletinYearHeader({ year }) {
    return (
        <Grid item xs={12}>
            <div style={{ borderRadius: '4px', display: 'flex', flexDirection: 'column', textAlign: 'center', color: '#fff' }}>
                <Typography variant="h4">
                    {year}
                </Typography>
            </div>
        </Grid>
    )
}
import { ListItemButton } from '@mui/material';
import React from 'react';
import SearchItemText from './SearchItemText';

export default function SearchEntryButton({ onClick, title, isPageShowing, query, pageNumber }) {
    return (
        <ListItemButton
            onClick={onClick}
        >
            <SearchItemText
                isPageShowing={isPageShowing}
                title={title}
                query={query}
                pageNumber={pageNumber}
            />
        </ListItemButton>
    );
}
import { Stack } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { preloadDocumentSectionPdf, useDocumentSectionPdf } from "../../../fetch/fetchPdf";
import { useIsInViewport } from "../../../hooks";
import ScrollSectionPagePane from "./ScrollSectionPagePane";
import ScrollSectionPagePaneDocumentView from './ScrollSectionPagePaneDocumentView';

export default function ScrollSectionPane({
    section, previousSection, nextSection,
    partNumber, pages, hasCover, pageCount, documentDimensions, onComeIntoVisibility, setPageNumber,
    onToggleZoom }) {
    const { fileName, start, end } = section;
    const sectionContainerRef = useRef(null);
    const [isSectionInViewport, intersectionRatio] = useIsInViewport(sectionContainerRef);

    useEffect(() => {
        sectionContainerRef.current.id = fileName
    }, [sectionContainerRef?.current]);

    const offset = start - 1;

    const { pdfData, isLoading: isPdfLoading } = useDocumentSectionPdf(partNumber, isSectionInViewport ? fileName : null);

    const currentPageNumber = +(window.location.hash.replace('#page=', ''));
    const canPreloadNext = !!nextSection && currentPageNumber + 4 >= nextSection.start
    const canPreloadPrevious = !!previousSection && currentPageNumber - 2 <= previousSection.end;

    useEffect(() => {
        if (isSectionInViewport && canPreloadNext) {
            preloadDocumentSectionPdf(partNumber, nextSection.fileName);
        }
    }, [isSectionInViewport, canPreloadNext]);

    useEffect(() => {
        if (isSectionInViewport && canPreloadPrevious) {
            preloadDocumentSectionPdf(partNumber, previousSection.fileName);
        }
    }, [isSectionInViewport, canPreloadPrevious]);

    return (
        <Stack justifyContent='center' ref={sectionContainerRef} style={{ minHeight: documentDimensions.documentHeight * pages.length }}>
            {pages.map((page) => {
                const { pageNumber } = page;
                return (
                    <ScrollSectionPagePane
                        key={pageNumber}
                        hasCover={hasCover}
                        pageCount={pageCount}
                        documentDimensions={documentDimensions}
                        partNumber={partNumber}
                        pageNumber={pageNumber}
                        onComeIntoVisibility={onComeIntoVisibility}
                        isSectionInViewport={isSectionInViewport}
                        onToggleZoom={onToggleZoom}
                    >
                        {!!pdfData && !isPdfLoading ? (
                            <ScrollSectionPagePaneDocumentView
                                pdfData={pdfData}
                                pageNumber={pageNumber - offset}
                                setPageNumber={(pageNumber) => setPageNumber(pageNumber + offset)}
                                documentDimensions={documentDimensions}
                                onToggleZoom={onToggleZoom}
                            />
                        ) : null}
                    </ScrollSectionPagePane>
                );
            })}
        </Stack>
    );
}
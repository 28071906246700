import React from 'react';
import { Grid, Paper } from '@mui/material';
import { useWindowDimensions } from '../../hooks';

export default function FlyoutPane({ children }) {
    const { width: viewportWidth } = useWindowDimensions();
    return (
        <Grid item style={{ width: `${viewportWidth * .30}px` }}>
            <Paper
                sx={{
                    p: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    maxHeight: 'calc(100vh - 7.5em)',
                    overflow: 'auto'
                }}
            >
                {children}
            </Paper>
        </Grid>
    )
}
import React from 'react';
import { Fade } from '@mui/material';

import './DocumentListItemIcon.css';
import { useQuarterThumbImage, useHalfThumbImage } from '../../fetch/fetchImage';
import Spinner from '../spinner/Spinner';

export default function ProgressiveThumbnail({ partNumber, documentTypeId, title }) {
    const { image: quarterImage, isLoading: isQuarterImageLoading } = useQuarterThumbImage(documentTypeId != 7 ? partNumber : null);
    const { image, isLoading } = useHalfThumbImage(documentTypeId != 7 && !!quarterImage && !isQuarterImageLoading ? partNumber : null);

    return (
        <>
            {isQuarterImageLoading ? (
                <Spinner />
            ) : null}

            <Fade in={!isQuarterImageLoading} timeout={25}>
                <div>
                    <img
                        alt={title}
                        title={title}
                        src={quarterImage}
                        style={{
                            width: '100%', height: '100%',
                            display: !!quarterImage && !isQuarterImageLoading && !image ? 'block' : 'none'
                        }}
                    />
                    <img
                        alt={title}
                        title={title}
                        src={image}
                        style={{
                            width: '100%', height: '100%',
                            display: !!image && !isLoading ? 'block' : 'none'
                        }}
                    />
                </div>
            </Fade>
        </>
    );
}
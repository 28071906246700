import React from 'react';
import styled from '@emotion/styled';
import { useThumbImage } from '../../../fetch/fetchImage';
import { PageSpinner } from '../../documentViewCore';

const CoverContainer = styled('div')(({ theme, documentWidth, documentHeight }) => {
    return ({
        width: documentWidth,
        height: documentHeight,
        marginRight: theme.spacing(1),
        background: 'transparent'
    });
})

export default function DocumentCoverPage({ documentWidth, documentHeight, partNumber }) {
    const { image, isLoading } = useThumbImage(partNumber);

    return (
        <CoverContainer
            documentWidth={documentWidth}
            documentHeight={documentHeight}
        >
            {isLoading ? (
                <PageSpinner documentHeight={documentHeight} />
            ) : (<img
                src={image}
                style={{ width: '100%', height: '100%', display: 'block' }}
            />
            )}
        </CoverContainer>
    )
}
import React, { useState } from "react";
import { Document, Page } from 'react-pdf';

export default function ScrollSectionPagePaneDocumentView({ pdfData, pageNumber, setPageNumber, documentDimensions, onToggleZoom }) {
    const [docRendered, setDocRendered] = useState(false);

    return (
        <div style={{ display: !docRendered ? 'none' : null }} onDoubleClick={onToggleZoom}>
            <Document file={pdfData} externalLinkTarget="_blank" loading='' onLoadSuccess={(args) => { setTimeout(() => setDocRendered(true), 500) }} onItemClick={({ dest, pageIndex, pageNumber }) => { setPageNumber(pageNumber) }}>
                <Page loading='' noData='' pageNumber={pageNumber} renderTextLayer={true} height={documentDimensions.documentHeight} width={documentDimensions.documentWidth} renderAnnotationLayer={true} />
            </Document>
        </div>
    );

}
import React, { useEffect, useRef } from "react";
import ImagePageContainer from "./ImagePageContainer";
import { DocumentTableOfContentsPage } from "../../documentViewCore";
import { useIsInViewport, useWindowDimensions } from "../../../hooks";

export default function ScrollViewTocPage({ hasCover, outline, documentDimensions, pageRatio, setPageNumber, onToggleZoom, scrollRef }) {
    const ref = useRef(null);
    const [isInViewport, intersectionRatio] = useIsInViewport(ref);
    const { height: viewportHeight } = useWindowDimensions();

    const pageChangeHeightThreshold = (viewportHeight - 139) / 2;
    
    const handleComeIntoVisibility = (pageNumber) => {
        const scrollTop = scrollRef.current.scrollTop
        window.location.hash = `page=${pageNumber}`;
        scrollRef.current.scrollTop = scrollTop;
    }

    useEffect(() => {
        if (!!ref.current) {
            ref.current.id = `page=0`
        }
    }, [ref?.current]);

    useEffect(() => {
        if (isInViewport && intersectionRatio * documentDimensions.documentHeight >= pageChangeHeightThreshold) {
            handleComeIntoVisibility(0);
        }
    }, [isInViewport, intersectionRatio, pageChangeHeightThreshold, documentDimensions.documentHeight]);


    if (!hasCover) {
        return null;
    }

    return (
        <ImagePageContainer
            documentDimensions={documentDimensions}
            ref={ref}
            onDoubleClick={onToggleZoom}
        >
            <DocumentTableOfContentsPage
                documentWidth={documentDimensions.documentWidth}
                documentHeight={documentDimensions.documentHeight}
                outline={outline}
                setPageNumber={(pageNumber) => setPageNumber(pageNumber)}
                pageRatio={pageRatio}
            />
        </ImagePageContainer>
    );
}
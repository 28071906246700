import React, { useRef } from "react";
import DocumentCoverPage from "./DocumentCoverPage";
import PdfPageButton from "../core/PdfPageButton";
import styled from "@emotion/styled";
import { DocumentTableOfContentsPage, DocumentScrollViewer, DocumentPane } from "../../documentViewCore";
import { useIsSinglePage } from "../../../hooks";

export default function CoverViewPage({
    currentPage, documentDimensions, partNumber, outline, setPageNumber, pageRatio }) {
    const { documentHeight, documentWidth } = documentDimensions;
    const { isSinglePageMode, isExpandMode } = useIsSinglePage();
    const scrollContainerWdith = !isSinglePageMode ? documentWidth * 2 : documentWidth;

    const scrollRef = useRef(null);

    const handleSetPageNumber = (pageNumber) => {
        setPageNumber(pageNumber);

        if (isExpandMode && !!scrollRef.current) {
            const scrollerNode = scrollRef.current;
            scrollerNode.scrollTop = 0;
        }
    }

    if (isSinglePageMode && currentPage === -1) {
        return (
            <>
                <DocumentScrollViewer width={scrollContainerWdith} ref={scrollRef}>
                    <DocumentPane>
                        <PaneContainer documentWidth={documentWidth} documentHeight={documentHeight}>
                            <DocumentCoverPage
                                documentWidth={documentWidth}
                                documentHeight={documentHeight}
                                partNumber={partNumber}
                            />
                        </PaneContainer>
                    </DocumentPane>
                </DocumentScrollViewer>
                <PdfPageButton
                    show
                    direction='next'
                    documentHeight={documentHeight}
                    onClick={() => handleSetPageNumber(0)}
                />
            </>
        );
    }

    if (isSinglePageMode && currentPage === 0) {
        return (
            <>
                <PdfPageButton
                    show
                    direction='previous'
                    documentHeight={documentHeight}
                    onClick={() => handleSetPageNumber(-1)}
                />
                <DocumentScrollViewer width={scrollContainerWdith} ref={scrollRef}>
                    <DocumentPane>
                        <PaneContainer documentWidth={documentWidth} documentHeight={documentHeight}>
                            <DocumentTableOfContentsPage
                                documentWidth={documentWidth}
                                documentHeight={documentHeight}
                                outline={outline}
                                setPageNumber={(pageNumber) => handleSetPageNumber(pageNumber)}
                                pageRatio={pageRatio}
                            />
                        </PaneContainer>
                    </DocumentPane>
                </DocumentScrollViewer>
                <PdfPageButton
                    show
                    direction='next'
                    documentHeight={documentHeight}
                    onClick={() => handleSetPageNumber(1)}
                />
            </>
        );
    }

    return (
        <>
            <DocumentScrollViewer width={scrollContainerWdith} ref={scrollRef}>
                <DocumentPane>
                    <div style={{ width: '64px' }}></div>
                    <PaneContainer documentWidth={documentWidth} documentHeight={documentHeight} padRight>
                        <DocumentCoverPage
                            documentWidth={documentWidth}
                            documentHeight={documentHeight}
                            partNumber={partNumber}
                        />
                    </PaneContainer>

                    <PaneContainer documentWidth={documentWidth} documentHeight={documentHeight}>
                        <DocumentTableOfContentsPage
                            documentWidth={documentWidth}
                            documentHeight={documentHeight}
                            outline={outline}
                            setPageNumber={(pageNumber) => handleSetPageNumber(pageNumber)}
                            pageRatio={pageRatio}
                        />
                    </PaneContainer>
                </DocumentPane>
            </DocumentScrollViewer>

            <PdfPageButton
                show
                direction='next'
                documentHeight={documentHeight}
                onClick={() => handleSetPageNumber(1)}
            />
        </>
    );
}

const PaneContainer = styled('div')(({ theme, documentWidth, documentHeight, padRight }) => {
    return ({
        marginRight: padRight ? theme.spacing(1) : null,
        width: documentWidth,
        height: documentHeight
    });
});
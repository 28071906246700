import React from 'react';
import { Box } from '@mui/material';
import DocumentList from './documentList/DocumentList';

export default function LiteratureHome() {
  return (
    <Box component="main" sx={{ p: 0, width: '100%' }}>
      <DocumentList />
    </Box>
  );
}

import React, { useState } from "react";
import ContentContainer from "./contentContainer";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Table } from "@mui/material";
import PageTitle from "./pageTitle";
import { useAllFamilies, useImageFile } from '../../fetch/familyFetch';
import Spinner from "../spinner/Spinner";
import { Link } from "react-router-dom";

export default function G3SupportedProducts() {
    const { data, isLoading } = useAllFamilies();


    return (
        <ContentContainer>
            <PageTitle title='Supported Products' />

            <Box component="main" sx={{ p: 3, width: '100%' }}>
                <Grid container className="support-toolbox">
                    <Grid item xs={12}>
                        {!isLoading ? (
                            <Table>
                                <tbody>
                                    {data.map(group => {
                                        return (
                                            <React.Fragment key={group[0].groupName}>
                                                <tr>
                                                    <td colSpan={6}>
                                                        <span style={{ fontSize: '1.25em', color: '#777' }}>
                                                            {group[0].groupName}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th></th>
                                                    <th style={{ textAlign: 'left' }}>Product Family</th>
                                                    <th style={{ textAlign: 'left' }}>Serial Range</th>
                                                    <th style={{ textAlign: 'left' }}>Cable Type</th>
                                                    <th style={{ textAlign: 'left' }}>Product</th>
                                                    <th style={{ textAlign: 'left' }}>Module</th>
                                                    <th style={{ textAlign: 'left' }}>Diagnostic Port</th>
                                                    {/* <th>Literature</th> */}
                                                </tr>
                                                {group.map((item, index) => {
                                                    return (
                                                        <tr key={`${item.id}-${index}`}>
                                                            <td style={{ padding: '.5rem' }}></td>
                                                            <td>
                                                                {item.familyName}
                                                            </td>
                                                            <td>
                                                                {item.serialRange}
                                                            </td>
                                                            <td>
                                                                {item.cableType}
                                                            </td>
                                                            <td>
                                                                <FamilyImageView
                                                                    folder='product'
                                                                    fileName={item.productImageName}
                                                                    title={`${item.familyName} - Product Image`}
                                                                />
                                                            </td>
                                                            <td>
                                                                <FamilyImageView
                                                                    folder='module'
                                                                    fileName={item.moduleImageName}
                                                                    title={`${item.familyName} - Module`}
                                                                />
                                                            </td>
                                                            <td>
                                                                <FamilyImageView
                                                                    folder='port'
                                                                    fileName={item.portImageName}
                                                                    title={`${item.familyName} - Diagnostic Port`}
                                                                />
                                                            </td>
                                                            {/* <td style={{ textAlign: 'center' }}>
                                                                <Link to={`/literature?familyNameId=${item.id}`}>View</Link>
                                                            </td> */}
                                                        </tr>
                                                    );
                                                })}
                                            </React.Fragment>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        ) : <Spinner />}
                    </Grid>
                </Grid>
            </Box>
        </ContentContainer>
    );
}

const FamilyImageView = ({ folder, fileName, title }) => {
    const [showZoom, setShowZoom] = useState(false);
    const { imageData } = useImageFile(folder, fileName);
    return (
        <>
            <img src={imageData} style={{ maxHeight: '5em', cursor: 'pointer' }} onClick={() => setShowZoom(true)} />
            <Dialog open={showZoom} onClose={() => setShowZoom(false)}>
                <Box>
                    <DialogTitle>
                        {title}
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ textAlign: 'center', overflow: 'hidden' }}>
                            <img src={imageData} style={{ maxHeight: 'calc(100vh - 13em)', maxWidth: '100%' }} />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowZoom(false)} autoFocus style={{ color: "black" }}>
                            Close
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </>
    );
}
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { useMobileOrientation, isMobile } from 'react-device-detect';

export default function usePhoneMode() {
    const theme = useTheme();
    const phoneWidth = useMediaQuery(theme.breakpoints.down("sm"));
    const { isLandscape } = useMobileOrientation();

    return { isMobile: phoneWidth || isMobile, isLandscape }
}
import React from "react";
import FilterCollapseGroup from "./FilterCollapseGroup";
import { List, Skeleton } from "@mui/material";
import ListItemCheckbox from "./ListItemCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { useBulletinYears, useDocumentListCounts } from "../../../fetch/fetchDocuments";
import { changeFilter } from "../../../redux/libraryFiltersSlice";
import { useDocumentTypeList } from "../../../fetch/fetchClassificationItems";

export default function DocumentTypeFilterGroup() {
    const dispatch = useDispatch();
    const {
        type: documentType,
        year: filteredYear
    } = useSelector((state) => state.libraryFilters);

    const { documentTypes: sourceDocumentTypes } = useDocumentTypeList();
    const bulletinsFilterValue = sourceDocumentTypes.filter(it => it.id == 2 || it.id == 9).map(it => it.id).join(',');
    const allManualsFilterValue = sourceDocumentTypes.filter(it => it.id != 2 && it.id != 9).map(it => it.id).join(',');

    const isOtherChecked = documentType.indexOf('0') >= 0 || documentType.indexOf('6') >= 0 || documentType.indexOf('7') >= 0 || documentType.indexOf('8') >= 0;
    const showManualSubFilters = isOtherChecked || documentType.indexOf('1') >= 0 || documentType.indexOf('3') >= 0 ||
        documentType.indexOf('4') >= 0 || documentType.indexOf('5') >= 0;

    const {
        allManualTypeCount,
        totalManualCount: manualCount,
        totalBulletinCount: bulletinCount,
        serviceManualCount,
        installationManualCount,
        operationManualCount,
        colorDiagramCount,
        diagnosticManualCount,
        videoCount,
        otherCount
    } = useDocumentListCounts();
    const totalRecords = manualCount + bulletinCount;

    const {
        items: bulletinYearsRaw,
        isLoading: yearsIsLoading
    } = useBulletinYears();

    const bulletinYears = [...(bulletinYearsRaw || [])];
    if (!!bulletinYears && !!filteredYear) {
        const filteredYears = (filteredYear || '').split(',');
        filteredYears.forEach(fy => {
            if (bulletinYears.filter(it => it.year === fy).length === 0) {
                bulletinYears.push({ year: fy, documentCount: 0 });
            }
        });

        bulletinYears.sort((a, b) => {
            if (+a.year > +b.year) {
                return -1;
            }

            if (+a.year < +b.year) {
                return 1;
            }

            return 0;
        });
    }

    let subTypes = [];
    if (!!documentType && documentType != 2) {
        subTypes = sourceDocumentTypes.map(it => it.id).filter(it => it != 0 && it != 2 && it != 6 && it != 7 && it != 8 && it != 9);
    }

    const handleSubTypeChecked = (typeId) => {
        const isChecked = documentType.indexOf(typeId) >= 0;

        let newDocumentTypes = '';
        if (isChecked) {
            newDocumentTypes = documentType.split(',').filter(it => it != typeId).join(',');
        } else {
            newDocumentTypes = [...documentType.split(','), typeId].join(',');
        }

        newDocumentTypes = newDocumentTypes.split(',').sort((a, b) => { return a - b; }).join(',');

        dispatch(changeFilter({ fieldName: 'type', value: newDocumentTypes }));
    }

    const generateCountedCheckDisplay = (name, count) => {
        let displayName = name;
        if (!!count && count != 0) {
            displayName += ` (${count})`;
        }

        return displayName;
    }

    const handleOtherTypeChecked = () => {
        let newDocumentTypes = '';
        if (isOtherChecked) {
            newDocumentTypes = documentType.split(',').filter(it => it != 0 && it != 6 && it != 7 && it != 8).join(',');
        } else {
            newDocumentTypes = [...documentType.split(','), 0, 6, 7, 8].join(',');
        }

        newDocumentTypes = newDocumentTypes.split(',').sort((a, b) => { return a - b; }).join(',');

        dispatch(changeFilter({ fieldName: 'type', value: newDocumentTypes }));
    }

    const handleYearChecked = (year) => {
        if (!year) {
            dispatch(changeFilter({ fieldName: 'year', value: year }));
            return;
        }

        const isChecked = filteredYear.indexOf(year) >= 0;

        let newYearFilter = '';
        if (isChecked) {
            newYearFilter = filteredYear.split(',').filter(it => it != year).join(',');
        } else if (!isChecked && filteredYear.length > 0) {
            newYearFilter = [...filteredYear.split(','), year].join(',');
        } else {
            newYearFilter = year;
        }

        dispatch(changeFilter({ fieldName: 'year', value: newYearFilter }));
    }

    return (
        <FilterCollapseGroup
            title='Document Type'
            expandedByDefault
        >
            <List component="div" disablePadding>
                <ListItemCheckbox
                    id='document-type-all'
                    text={generateCountedCheckDisplay('All', totalRecords)}
                    isChecked={!documentType}
                    onClick={() => {
                        dispatch(changeFilter({ fieldName: 'type', value: '' }));
                        dispatch(changeFilter({ fieldName: 'year', value: '' }));
                    }}
                />
                <ListItemCheckbox
                    id='document-type-manuals'
                    text={generateCountedCheckDisplay('Manuals', manualCount)}
                    isChecked={documentType == allManualsFilterValue}
                    isIndeterminate={documentType != allManualsFilterValue && showManualSubFilters}
                    onClick={() => {
                        dispatch(changeFilter({ fieldName: 'type', value: allManualsFilterValue }));
                        dispatch(changeFilter({ fieldName: 'year', value: '' }));
                    }}
                />
                {showManualSubFilters ? (
                    <>
                        {subTypes.map(sub => {
                            const subType = sourceDocumentTypes.filter(it => it.id == sub)[0];
                            const isChecked = documentType.indexOf(sub) >= 0;

                            let subCount = '';
                            switch (sub.toString()) {
                                case '0':
                                    subCount = allManualTypeCount;
                                    break;
                                case '1':
                                    subCount = serviceManualCount;
                                    break;
                                case '3':
                                    subCount = installationManualCount;
                                    break;
                                case '4':
                                    subCount = operationManualCount;
                                    break;
                                case '5':
                                    subCount = diagnosticManualCount;
                                    break;
                                case '6':
                                    subCount = colorDiagramCount;
                                    break;
                                case '7':
                                    subCount = videoCount;
                                    break;
                                case '8':
                                    subCount = otherCount;
                                    break;
                            }

                            return (
                                <ListItemCheckbox
                                    key={sub}
                                    marginLeft={1}
                                    id={`document-type-${sub}-manuals`}
                                    text={generateCountedCheckDisplay(subType?.name, subCount)}
                                    isChecked={isChecked}
                                    onClick={() => handleSubTypeChecked(sub)}
                                />
                            );
                        })}
                        <ListItemCheckbox
                            marginLeft={1}
                            id={`document-type-other-manuals`}
                            text={generateCountedCheckDisplay('Other', allManualTypeCount + colorDiagramCount + videoCount + otherCount)}
                            isChecked={isOtherChecked}
                            onClick={handleOtherTypeChecked}
                        />
                    </>
                ) : null}
                <ListItemCheckbox
                    id='document-type-bulletins'
                    text={generateCountedCheckDisplay('Bulletins', bulletinCount)}
                    isChecked={documentType == bulletinsFilterValue}
                    onClick={() => dispatch(changeFilter({ fieldName: 'type', value: '2,9' }))}
                />
                {documentType == '2,9' ? (
                    <div style={{ marginLeft: '1em' }}>
                        <FilterCollapseGroup
                            title='Years'
                            expandedByDefault
                        >
                            {yearsIsLoading ? (
                                <div style={{ marginLeft: '2em' }}>
                                    <Skeleton variant="text" height={25} />
                                    <Skeleton variant="text" height={25} />
                                </div>
                            ) : (
                                <ListItemCheckbox
                                    marginLeft={1}
                                    id={`document-year-all`}
                                    text='All'
                                    isChecked={!filteredYear}
                                    onClick={() => handleYearChecked('')}
                                />
                            )}
                            {(bulletinYears || []).map(item => {
                                const { year, documentCount } = item;

                                return (
                                    <ListItemCheckbox
                                        key={year}
                                        marginLeft={1}
                                        id={`document-year-${year}`}
                                        text={`${year} (${documentCount})`}
                                        isChecked={filteredYear.indexOf(year) >= 0}
                                        onClick={() => handleYearChecked(year)}
                                    />
                                );
                            })}
                        </FilterCollapseGroup>
                    </div>
                ) : null}
            </List>
        </FilterCollapseGroup>
    );
}
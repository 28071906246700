class StorageHelper {
    constructor() {
        this.storage = window.localStorage;
    }

    getListView = () => {
        return this.storage.getItem('ListView') || 'large';
    }

    setListView = (viewMode) => {
        this.storage.setItem('ListView', viewMode);
    }

    getPersistedPath = () => {
        return this.storage.getItem('PersistedPath') || ''
    }

    setPersistedPath = (path) => {
        this.storage.setItem('PersistedPath', path);
    }

    getActivationKey = () => {
        return this.storage.getItem('ActivationKey') || ''
    }

    setActivationKey = (activationKey) => {
        this.storage.setItem('ActivationKey', activationKey);
    }
}


export const storageHelper = new StorageHelper();
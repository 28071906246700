import { useTheme } from "@emotion/react";
import { Grid, ToggleButtonGroup } from "@mui/material";
import React from "react";
import ListFiltersButton from "../filters/ListFiltersButton";
import StyledToggleButton from "./StyledToggleButton";
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge';
import ViewListIcon from '@mui/icons-material/ViewList';
import { useSelector } from "react-redux";
import { storageHelper } from "../../../helpers/storageHelper";
import usePhoneMode from "../../../hooks/usePhoneMode";

export default function DocumentListSubMenu({ filtersOpen, onToggleOpenClick, stateListView, setListView }) {
    const theme = useTheme();
    const { isMobile } = usePhoneMode();

    const { type: documentType } = useSelector((state) => state.libraryFilters)
    const listView = documentType != '2,9' ? stateListView : 'list';

    return (
        <Grid container style={{ height: theme.subBar.height, background: '#FFF' }}>
            <Grid item xs={12} sm={8} md={10} xl={11}>
                {!filtersOpen ? (
                    <ListFiltersButton
                        onToggleOpenClick={onToggleOpenClick}
                    />
                ) : null}
            </Grid>
            <Grid item xs={0} sm={4} md={2} xl={1}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {!isMobile && documentType != '2,9' ? (
                        <ToggleButtonGroup
                            value={listView}
                            exclusive
                            onChange={(event, newListView) => {
                                if (!!newListView) {
                                    storageHelper.setListView(newListView);
                                    setListView(newListView);
                                }
                            }}
                            style={{ marginLeft: '0.5em' }}
                        >
                            <StyledToggleButton value='large' title='Large Icons'>
                                <PhotoSizeSelectActualIcon />
                            </StyledToggleButton>
                            <StyledToggleButton value='medium' title='Small Icons'>
                                <PhotoSizeSelectLargeIcon />
                            </StyledToggleButton>
                            <StyledToggleButton value='list' title='Details'>
                                <ViewListIcon />
                            </StyledToggleButton>
                        </ToggleButtonGroup>
                    ) : null}
                </div>
            </Grid>
        </Grid>
    );
}
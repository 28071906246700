import React from 'react';
import { Box, Button, Grid, Paper } from '@mui/material';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Navigate } from 'react-router-dom';
import { loginRequest } from '../helpers/authConfig';

export default function LogoutPage() {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const handleLogin = () => {
        instance.loginRedirect(loginRequest)
        instance.loginPopup(loginRequest)
    }

    if (isAuthenticated) {
        return <Navigate to="/literature" />;
    }

    return (
        <Box component="main" sx={{ p: 3, width: '100%' }}>
            <Grid container>
                <Grid item xs={12} lg={4} md={6}>
                    <Paper
                        sx={{
                            p: 2,
                        }}
                    >
                        You have been signed out of Literature.
                        <br />
                        <br />
                        <Button variant='contained' onClick={handleLogin}>Login</Button>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}
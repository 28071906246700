import useSWR from 'swr';

export const useAllFamilies = () => {
    const { data, error, isValidating, isLoading, mutate } = useSWR(
        '/api/v1/familyname/all',
        fetcher,
        swrConfig);

    return { data, error, isValidating, isLoading, mutate };
}

const fetcher = (url) => {
    return fetch(url)
        .then(response => response.json());
}

export const useImageFile = (folder, fileName) => {
    const { data } = useSWR(
        !!folder && !!fileName ? `api/v1/file/images/${folder}/${fileName}` : null,
        imageFetcher,
        swrConfig);

    return { imageData: data };
};

const imageFetcher = (url) => {
    return fetch(url)
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob));
}

const swrConfig = {
    revalidateOnFocus: false,
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Never retry on 4XX error.
        if (error.status >= 400 && error.status < 500) return;

        //   // Never retry for a specific key.
        //   if (key === '/api/user') return

        // Only retry up to 1 times.
        if (retryCount > 1) return;

        // Retry after 1 seconds.
        setTimeout(() => { revalidate({ retryCount }); }, 1000)
    }
};
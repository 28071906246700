import React from "react";
import { Button, Divider, List, ListItem, Paper } from "@mui/material";

const HorizontalList = ({ children }) => {
    return (
        <List style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
            {children}
        </List>
    );
}

const HorizontalListItem = ({ children }) => {
    return (
        <ListItem style={{ width: 'auto', padding: '4px' }}>
            {children}
        </ListItem>
    );
}

const HorizontalDivider = () => {
    return (
        <Divider style={{ borderRightWidth: 'thin' }} />
    );
}

export { HorizontalList, HorizontalListItem, HorizontalDivider }
import { styled } from "@mui/material";

const ImagePageContainer = styled('div')(({ theme, documentDimensions, image }) => {
    return ({
        background: theme.palette.background.paper,
        backgroundImage: !!image ? `url(${image})` : null,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: documentDimensions.documentWidth,
        height: documentDimensions.documentHeight,
        marginBottom: '1em'
    });
});
export default ImagePageContainer
class ParameterHelper {
    getUrlParameters = (defaults) => {
        const results = {};
        const params = new URLSearchParams(window.location.search);
        Object.keys(defaults).forEach((key) => {
            if (Number.isInteger(defaults[key])) {
                const paramVal = params.get(key);
                if (paramVal != null && paramVal != undefined && paramVal != '') {
                    results[key] = +paramVal;
                } else {
                    results[key] = defaults[key];
                }
            }
            else {
                results[key] = params.get(key) || defaults[key];
            }
        });
        return results;
    }
}

export const parameterHelper = new ParameterHelper();
import React from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Divider, IconButton, ListItem, ListItemText } from '@mui/material';

export default function ClearFilterListItem({ show, text, onClick }) {
    if (!show) {
        return null;
    }

    return (
        <>
            <Divider />
            <ListItem
                secondaryAction={
                    <IconButton onClick={onClick}>
                        <HighlightOffIcon color='primary' />
                    </IconButton>
                }
            >
                <ListItemText primary={text} />
            </ListItem>
        </>
    )
}
import useSWR from 'swr';
import { httpResponseToJSON } from '../helpers/fetchHelpers';
import { useDebounce } from 'use-debounce';
import { getAuthenticatedHeaders } from './fetchHeaders';

export const useFetchProfile = (fetch) => {
    const { data, error, isValidating, isLoading } = useSWR(
        fetch ? '/user/profile' : null,
        fetcher,
        swrConfig);

    return { data, error, isValidating, isLoading };
}

export const useDealerExists = (accountNumber) => {
    const [debouncedAccountNumber, callback] = useDebounce(getDealerExistsPath(accountNumber), 1000);
    const { data, error, isValidating, isLoading } = useSWR(
        debouncedAccountNumber,
        fetcher,
        swrConfig);

    return { exists: data || false, error, isValidating, isLoading: isLoading || callback.isPending() };
}
const getDealerExistsPath = (accountNumber) => {
    if (!accountNumber || accountNumber.length < 4) {
        return null;
    }

    return `/user/validatedealer/${accountNumber}`;
}

export const useLicenseKeyValid = (accountNumber, licenseKey, dealerExists) => {
    const [debouncedLicenseKey, callback] = useDebounce(getValidateLicenseKey(accountNumber, licenseKey, dealerExists), 1000);
    const { data, error, isValidating, isLoading } = useSWR(
        debouncedLicenseKey,
        fetcher,
        swrConfig);

    let exists = false;
    if(!!data && (data.status == 4 || data.status == 6)) {
        exists = true;
    }

    return { exists, error, isValidating, isLoading: isLoading || callback.isPending() };
}

const getValidateLicenseKey = (accountNumber, licenseKey, dealerExists) => {
    if (!accountNumber || accountNumber.length < 4 || !licenseKey || licenseKey.length != 29 || !dealerExists) {
        return null;
    }

    return `/user/ValidateG3LicenseKey/${licenseKey}/${accountNumber}`;
}

const fetcher = (url) => {
    return getAuthenticatedHeaders().then((headers) => {
        return fetch(url, { headers })
            .then(response => httpResponseToJSON(response, 'Error loading data'/*, showError*/))
    });
}

export const useDealerExistsAnon = (accountNumber) => {
    const [debouncedAccountNumber, callback] = useDebounce(getDealerExistsAnonPath(accountNumber), 1000);
    const { data, error, isValidating, isLoading } = useSWR(
        debouncedAccountNumber,
        fetcherAnon,
        swrConfig);

    return { exists: data || false, error, isValidating, isLoading: isLoading || callback.isPending() };
}

const getDealerExistsAnonPath = (accountNumber) => {
    if (!accountNumber || accountNumber.length < 4) {
        return null;
    }

    return `/api/v1/account/ValidateDealer/${accountNumber}`;
}

const fetcherAnon = (url) => {
        return fetch(url)
            .then(response => httpResponseToJSON(response, 'Error loading data'/*, showError*/));
}

const swrConfig = {
    revalidateOnFocus: false,
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Never retry on 4XX error.
        if (error.status >= 400 && error.status < 500) return;

        //   // Never retry for a specific key.
        //   if (key === '/api/user') return

        // Only retry up to 1 times.
        if (retryCount > 1) return;

        // Retry after 1 seconds.
        setTimeout(() => { revalidate({ retryCount }); }, 1000)
    }
};
import React from 'react';
import { Button, Chip, Grid, Paper } from '@mui/material';
import usePhoneMode from '../../hooks/usePhoneMode';

import TypeTitle from './TypeTitle';
import { useDocumentTypeList, useLanguageList } from '../../fetch/fetchClassificationItems';
import { useNavigate } from 'react-router-dom';
import NewBanner from './NewBanner';
import ProgressiveThumbnail from './ProgressiveThumbnail';
import VideoThumbnail from './VideoThumbnail';
import { useSelector } from 'react-redux';

export default function DocumentListItemDetails({ document, isNew, small }) {
    const { isMobile } = usePhoneMode();
    const navigate = useNavigate();
    const { partNumber, displayPartNumber, title, documentTypeId, year, familyNames, language, masterPartNumber, masterLanguage, businessGroupIds } = document;
    const { documentTypes } = useDocumentTypeList();
    const businessGroups = useSelector((state) => state.permissions.businessGroups)
    const { languages } = useLanguageList();

    let partTitle = partNumber;
    if (documentTypeId == 2 && !!partNumber && partNumber === displayPartNumber) {
        let type = partNumber.substring(0, 3);
        let last = partNumber.substring(3);
        let code = last.split('-')[1];

        if (code.indexOf('R') >= 0) {
            code = code.substring(0, code.indexOf('R'));
        }

        if (language != 'en' && partNumber.indexOf('_') > 0) {
            last = last.substring(0, last.indexOf('_'));
        }

        // TODO database these values?
        // Breaking updates (9/10/2024) They are now databased but there are some edge cases that might still hit this
        switch (type) {
            case 'sob':
                type = 'Outboard';
                break;
            case "smc":
                type = "MerCruiser";
                if (+code >= 61) {
                    type = 'Mercury Diesel';
                }
                break;
            case "sho":
                type = "Racing Outboard";
                break;
            case "shs":
                type = "Racing Sterndrive";
                break;
        }

        partTitle = `${type} ${last}`
    }
    else if (documentTypeId == 2 && !!displayPartNumber && partNumber !== displayPartNumber) {
        partTitle = displayPartNumber
    } else if (documentTypeId == 9) {
        // this will break in the year 2100, probably should fix by then
        const truncatedPartNumber = partNumber.substring(partNumber.indexOf('20'));
        const allowedGroupIds = businessGroups.map(it => it.id);
        const filteredBusinessGroupIds = businessGroupIds.filter(it => allowedGroupIds.indexOf(it.businessGroupId) >= 0);

        partTitle = `${filteredBusinessGroupIds.map(item => {
            const businessGroup = businessGroups.filter(bg => bg.id == item.businessGroupId)[0];
            if (!businessGroup) {
                return '';
            }

            return !!businessGroup.bulletinDisplayText ? businessGroup.bulletinDisplayText : businessGroup.name;
        }).join(', ')} ${truncatedPartNumber}`;
    }

    return (
        <Grid item xs={1} className='document-list-item-details' style={{ position: 'relative' }}>
            <Paper
                sx={{
                    p: 1.5,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    overflow: 'hidden',
                    cursor: 'pointer'
                }}
                onClick={(event) => {
                    event.stopPropagation();
                    navigate(documentTypeId != 7 ? `/literature/view/${partNumber}` : `/literature/video/${partNumber}`);
                }}
            >
                {isNew ? <NewBanner>New</NewBanner> : null}
                <Grid container style={{ marginTop: isNew ? '-2em' : null }}>
                    {!isMobile ? (
                        <Grid item xs={4} sm={small ? 2 : 3} md={small ? 1 : 2} xl={1}>
                            {documentTypeId != 7 ? (
                                <ProgressiveThumbnail
                                    partNumber={partNumber}
                                    documentTypeId={documentTypeId}
                                    title={title}
                                />
                            ) : (
                                <VideoThumbnail videoId={partNumber} title={title} minHeight='10em' />
                            )}
                        </Grid>
                    ) : null}
                    <ListItemTextContainer small={small}>
                        {!small && documentTypeId != 7 ? (
                            <TypeTitle detailsMode documentTypeId={document.documentTypeId}>
                                {documentTypes.filter(it => it.id == document.documentTypeId)[0]?.name}
                            </TypeTitle>
                        ) : null}
                        <h1 style={{ fontSize: '1.5em' }}>{title}</h1>
                        <span>{documentTypeId != 7 ? `${partTitle} | ` : null}{year} {!!familyNames ? ' | ' + familyNames.join(', ') : null}</span>
                        {!!language && !!languages && language != 'en' ? (
                            <div>
                                <Chip label={languages.filter(it => it.code == language)[0]?.nameLocal} />
                                {!!masterPartNumber ? (
                                    <Button
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            navigate(`/literature/view/${masterPartNumber}`);
                                        }}>
                                        View in {languages.filter(it => it.code == masterLanguage)[0]?.nameLocal}
                                    </Button>
                                ) : null}
                            </div>
                        ) : null}
                    </ListItemTextContainer>
                </Grid>
            </Paper>
        </Grid>
    );
}

function ListItemTextContainer({ small, children }) {
    const { isMobile } = usePhoneMode();

    if (isMobile) {
        return (
            <Grid item xs={12} sx={{ p: 2 }}>
                {children}
            </Grid>
        );
    }

    return (
        <Grid item xs={8} sm={small ? 10 : 9} md={small ? 11 : 10} xl={11} sx={{ p: 2 }}>
            {children}
        </Grid>
    )
}
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dismissError } from '../redux/notificationsSlice';

export default function ErrorModal() {
    const dispatch = useDispatch();
    const errorList = useSelector((state) => state.notifications.errorList);

    if(!errorList || errorList.length <= 0) {
        return null;
    }

    const message = errorList[0];

    const handleAccept = () => {
        dispatch(dismissError());
    }

    return (
        <Dialog
            open={!!prompt}
            onClose={handleAccept}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
        >
            <DialogTitle id="dialog-title">
                Error
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAccept} autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}
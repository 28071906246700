import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function useFixPagePathAndScroll(page, ready) {
    const location = useLocation();

    useEffect(() => {
        if (!!page && ready) {
            const params = new URLSearchParams(location.search);
            params.delete('page');
            let newPath = location.pathname;
            if (!!params.toString()) {
                newPath += `?${params.toString()}`;
            }
            window.history.replaceState({}, null, newPath);

            setTimeout(() => {
                var element = document.getElementById(`page=${page}`);
                if (!isDomElementInViewport(document.getElementById(`page=${page}`))) {
                    element.scrollIntoView(true);
                }
            }, 250);
        }
    }, [page, ready]);
}

export function useSwitchFromScrollToSideBySide(ready) {
    const location = useLocation();

    if (!!location.hash && ready) {
        const params = new URLSearchParams(location.search);
        params.set('page', location.hash.replace('#page=', ''));
        
        let newPath = location.pathname;
        if (!!params.toString()) {
            newPath += `?${params.toString()}`;
        }

        window.history.replaceState({}, null, newPath);
    }
}

function isDomElementInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}
import React from 'react';
import { Stack } from '@mui/material';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

export default function DocumentPane({ children }) {
    const isExpandMode = useSelector((state) => state.documentView.isExpandMode);

    return (
        <DocumentPaneContainer isExpandMode={isExpandMode}>
            <Stack direction='row' justifyContent='center'>
                {children}
            </Stack>
        </DocumentPaneContainer>
    )
}

const DocumentPaneContainer = styled('div')(({ theme, isExpandMode }) => {
    return ({
        paddingBottom: isExpandMode ? '4em' : null
    });
})
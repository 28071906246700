import { useState, useEffect } from 'react';

function getScrollInfo() {
  return {
    scrollTop: document.getElementById('scroll-viewer')?.scrollTop ?? 0
  };
}

export default function useWindowScrollTop() {
  const [scrollInfo, setScrollInfo] = useState(getScrollInfo());

  useEffect(() => {
    function handleChange() {
      setScrollInfo(getScrollInfo());
    }

    if (!!document && !!document.getElementById('scroll-viewer')) {
      document.getElementById('scroll-viewer').addEventListener('scroll', handleChange);
    }
    return () => {
      if (!!document && !!document.getElementById('scroll-viewer')) {
        document.getElementById('scroll-viewer').removeEventListener('scroll', handleChange);
      }
    }
  }, [document.getElementById('scroll-viewer')]);

  return scrollInfo;
}
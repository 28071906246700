import { Navigate, useLocation } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { useSelector } from "react-redux";
import { storageHelper } from "./storageHelper";
import { parameterHelper } from './parameterHelper';
import Spinner from "../components/spinner/Spinner";
import { Grid, Box } from "@mui/material";
import { useEffect } from "react";
import { loginRequest } from "./authConfig";

export function UserRoute({ children }) {
    const { inProgress, instance } = useMsal();
    const { pathname, search } = useLocation();
    const { activationKey } = parameterHelper.getUrlParameters({ activationKey: null });
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (!isAuthenticated && inProgress === InteractionStatus.None) {
            // user is not authenticated
            if (pathname.indexOf('view') >= 0 || !!search) {
                storageHelper.setPersistedPath(pathname + search);
            }

            if (!!activationKey) {
                storageHelper.setActivationKey(activationKey);
            }

            instance.loginRedirect(loginRequest)
        }
    }, [isAuthenticated, inProgress]);

    if (inProgress !== InteractionStatus.None || !isAuthenticated) {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Box
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <Spinner />
                    </Box>
                </Grid>
            </Grid>
        );
    }

    return children;
};

export function UserPermissionRoute({ children }) {
    const isAuthenticated = useIsAuthenticated();
    const { pathname } = useLocation();
    const { activationKey } = parameterHelper.getUrlParameters({ activationKey: null });
    const isLoaded = useSelector((state) => state.permissions.isLoaded);
    const isRecognized = useSelector((state) => state.permissions.isRecognized);

    if (isAuthenticated && !isLoaded) {
        // user is not authenticated
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Box
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <Spinner />
                    </Box>
                </Grid>
            </Grid>
        );
    }

    if (isAuthenticated && isLoaded && !isRecognized) {
        if (!!activationKey) {
            storageHelper.setActivationKey(activationKey);
        }

        // user is not authenticated
        return <Navigate to={`/literature/link?redirect=${encodeURIComponent(pathname)}`} />;
    }

    if (isLoaded && isRecognized && !!storageHelper.getActivationKey()) {
        // We are recognized so we can clear this out
        storageHelper.setActivationKey('');
    }

    return (
        <UserRoute>
            {children}
        </UserRoute>
    );
}
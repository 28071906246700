import React from 'react';
import { createRoot } from 'react-dom/client';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store'

import { pdfjs } from 'react-pdf';


import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import dayjs from 'dayjs';
import UIBase, { UIBaseLayout } from './UIBase';
import { UserPermissionRoute, UserRoute } from './helpers/routeHelpers';
import LiteratureHome from './components/Home';
import LinkAccount from './components/linkAccount/LinkAccount';
import Login from './components/login';
import PdfDocumentView from "./components/pdfDocumentView/DocumentView";
import ErrorPage from './ErrorPage';
import {
  ServicePortalHome, G3Components, G3SystemRequirements, G3SupportToolbox,
  G3SupportUpdates, G3ReleaseNotes, G3SupportedProducts, ContactForm,
  G3Download, DieselDownload
} from './components/servicePortal';
import VideoView from './components/videoView/VideoView';

import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./helpers/authConfig";
import LogoutPage from './components/logout';

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS
      ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ||
      event.eventType === EventType.SSO_SILENT_SUCCESS
    ) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  var relativeTime = require('dayjs/plugin/relativeTime')
  dayjs.extend(relativeTime)

  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
  ).toString();

  const rootElement = document.getElementById('root');
  const root = createRoot(rootElement);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <UIBase />,
      errorElement: <UIBaseLayout><ErrorPage /></UIBaseLayout>,
      children: [
        {
          path: "/",
          element: <ServicePortalHome />
        },
        {
          path: '/login',
          element: <Navigate to="/literature/login" replace />
        },
        {
          path: "/g3/components",
          element: <G3Components />
        },
        {
          path: '/g3/support/system-requirements',
          element: <G3SystemRequirements />
        },
        {
          path: '/g3/support/toolbox',
          element: <G3SupportToolbox />
        },
        {
          path: '/g3/support/updates',
          element: <G3SupportUpdates />
        },
        {
          path: '/g3/support/release-notes',
          element: <G3ReleaseNotes />
        },
        {
          path: '/g3/support/products',
          element: <G3SupportedProducts />
        },
        {
          path: '/contact',
          element: <ContactForm />
        },
        {
          path: '/g3/download',
          element: <G3Download />
        },
        {
          path: '/diesel/download',
          element: <DieselDownload />
        },
        {
          path: "/literature",
          children: [
            {
              path: "/literature/",
              element: (
                <UserPermissionRoute>
                  <LiteratureHome />
                </UserPermissionRoute>
              )
            },
            {
              path: '/literature/view/:partNumber',
              element: (
                <UserPermissionRoute>
                  <PdfDocumentView />
                </UserPermissionRoute>
              )
            },
            {
              path: '/literature/video/:partNumber',
              element: (
                <UserPermissionRoute>
                  <VideoView />
                </UserPermissionRoute>
              )
            },
            {
              path: '/literature/link',
              element: (
                <UserRoute>
                  <LinkAccount />
                </UserRoute>
              )
            },
            {
              path: '/literature/login',
              element: <Login />
            },
            {
              path: '/literature/logout',
              element: <LogoutPage />
            }
          ]
        }
      ]
    }
  ])

  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MsalProvider instance={msalInstance}>
          <RouterProvider router={router} />
        </MsalProvider>
      </PersistGate>
    </Provider>);

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://cra.link/PWA
  serviceWorkerRegistration.unregister();

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});

import React, { useEffect, useState } from 'react';
import { createTheme } from '@mui/material/styles';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Layout from './components/Layout';
import './custom.css';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { CustomNavigationClient } from './helpers/navigationClient';
import { msalInstance } from '.';

const litTheme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundColor: "#121212"
                }
            }
        }
    },
    palette: {
        primary: {
            main: '#0C4C6B'
        },
        secondary: {
            main: '#69A8A6'
        },
        typeTitle: {
            default: '#c00',
            defaultContrast: '#fff',
            serviceManual: '#c00',
            serviceManualContrast: '#fff',
            bulletin: '#0288d1',
            bulletinContrast: '#fff',
            installationManual: '#2e7d32',
            installationManualContrast: '#fff',
            operationManual: '#9c27b0',
            operationManualContrast: '#fff'
        }
    },
    typography: {
        fontFamily: 'Soleto'
    },
    documentView: {
        fontFamily: 'sans-serif',
        pageTitleFontSize: '1.1em',
        pageTitleFontWeight: '500',
        fontSize: '0.8em',
        pageMargin: '2.5rem'
    },
    mixins: {
        toolbar: {
            minHeight: 80
        }
    },
    appBar: {
        height: 80,
        buffer: 8
    },
    subBar: {
        height: 48
    }
});

const spTheme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundColor: "#fff",
                }
            }
        }
    },
    palette: {
        primary: {
            main: '#c00'
        }
    },
    appBar: {
        height: 56,
        // buffer: 8
    },
    documentView: {
        fontFamily: 'sans-serif',
        pageTitleFontSize: '1.1em',
        pageTitleFontWeight: '500',
        fontSize: '0.8em',
        pageMargin: '2.5rem'
    }
});

export default function UIBase() {
    return (
        <UIBaseLayout>
            <Outlet />
        </UIBaseLayout>
    );
}

export function UIBaseLayout({ children }) {
    const { pathname } = useLocation();
    const isInLiteratureView = pathname.indexOf('/literature') >= 0;

    useEffect(() => {
        document.title = isInLiteratureView ? 'Mercury Literature' : 'Mercury Service Portal';
    }, [isInLiteratureView]);

    return (
        <ClientSideNavigation instance={msalInstance}>
            <ThemeProvider theme={isInLiteratureView ? litTheme : spTheme}>
                <CssBaseline />
                <Layout>
                    {children}
                </Layout>
            </ThemeProvider>
        </ClientSideNavigation>
    );
}

function ClientSideNavigation({ instance, children }) {
    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    instance.setNavigationClient(navigationClient);

    // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
    const [firstRender, setFirstRender] = useState(true);
    useEffect(() => {
        setFirstRender(false);
    }, []);

    if (firstRender) {
        return null;
    }

    return children;
}
import React from "react";

export default function ContentContainer({ children }) {
    return (
        <div style={{
            overflowY: 'auto',
            overflowX: 'hidden',
            height: 'calc(100vh - 10.1em)',
            width: '100vw',
            marginTop: '1.1em'
        }}>
            {children}
        </div>
    );
}
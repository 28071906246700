import React, { useState } from "react";
import { Button } from "@mui/material";
import './g3Components.css';
import { apiHostUrl } from "../../helpers";
import Spinner from "../spinner/Spinner";

export default function DownloadLink({ fileName, label }) {
    const [manualDownloading, setManualDownloading] = useState(false);
    const url = `${apiHostUrl}/api/v1/file/media/${fileName}`

    const handleFullDownload = () => {
        if (manualDownloading) {
            return;
        }

        setManualDownloading(true);
        return fetch(url)
            .then(response => {
                return new Promise(function (resolve, reject) {
                    if (response.status >= 400) {
                        reject(response);
                        return
                    }

                    resolve(response);
                    return;
                });
            })
            .then(response => response.blob())
            .then(blob => saveAs(blob, fileName))
            .finally(() => setManualDownloading(false));
    }

    return (
        <div>
            <Button onClick={handleFullDownload} variant="link" style={{ color: '#0366d6', textTransform: 'unset', textDecoration: 'underline', paddingLeft: 0 }}>{label}</Button>
            {manualDownloading ? <Spinner displayInline width='32px' /> : null}
        </div>
    )
}
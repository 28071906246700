import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { parameterHelper } from '../../../helpers';
import { Box, Grid, Stack } from '@mui/material';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import {
    RelatedDocumentsMenu, DocumentTableOfContents, DocumentSearch,
    DocumentViewMenu, calculateDocumentPaneSize
} from '../../documentViewCore';
import { useWindowDimensions, useIsSinglePage } from '../../../hooks';

import DocumentPageView from '../DocumentPage/DocumentPageView';
import ZoomDialog from '../ZoomDialog';

const defaultParameters = { page: 0 };
const defaultParametersSinglePage = { page: 1 };
export default function NoCoverDocumentView({ partNumber, metadata, pageRatio }) {
    const { height: viewportHeight, width: viewportWidth } = useWindowDimensions();
    const location = useLocation();
    const navigate = useNavigate();

    const [documentDimensions, setDocumentDimensions] = useState({ documentHeight: viewportHeight, documentWidth: viewportHeight / 1.3 });

    const [showToc, setShowToc] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [showZoom, setShowZoom] = useState(false);
    const [zoomRotation, setZoomRotation] = useState(0);
    const [zoomScale, setZoomScale] = useState(1);

    const { isSinglePageMode, isExpandMode } = useIsSinglePage();
    const { page } = parameterHelper.getUrlParameters(!isSinglePageMode ? defaultParameters : defaultParametersSinglePage);

    const { pageCount, outline, sections, pages } = metadata;

    useEffect(() => {
        setDocumentDimensions(calculateDocumentPaneSize(
            showToc || showSearch,
            isSinglePageMode || page == 0,
            isExpandMode,
            viewportWidth,
            viewportHeight,
            pageRatio));
    }, [viewportHeight, viewportWidth, showToc, showSearch, isExpandMode, isSinglePageMode, page]);

    const currentPage = page;
    const nextPage = currentPage + 1;

    const currentSection = sections?.filter(it => it.start <= currentPage && it.end >= currentPage)[0];
    const currentPageOffset = currentSection?.start - 1;
    const isNextPageInCurrentSection = currentSection?.start <= nextPage && currentSection?.end >= nextPage

    const nextSection = sections?.filter(it => it.start <= nextPage && it.end >= nextPage)[0];
    const nextPageOffset = nextSection?.start - 1;

    const setPageNumber = (pageNumber) => {
        const isLeftPage = pageNumber % 2 == 0;
        const params = new URLSearchParams(location.search);
        params.set('page', isLeftPage || isSinglePageMode ? pageNumber : pageNumber - 1);

        if (pageNumber == (!isSinglePageMode ? defaultParameters.page : defaultParametersSinglePage.page)) {
            params.delete('page');
        }

        navigate(`${location.pathname}?${params.toString()}`);
    }

    useEffect(() => {
        if (!isSinglePageMode && currentPage % 2 == 1) {
            setPageNumber(currentPage - 1);
        }
    }, [isSinglePageMode, currentPage]);

    return (
        <Box component="main" sx={{ p: 0, overflow: 'hidden', maxHeight: '100%', width: '100%', marginTop: '8px' }}>
            <RelatedDocumentsMenu partNumber={partNumber} />
            <Grid container>
                <Grid item xs={12} xl={12}>
                    <Stack direction='row' spacing={2} justifyContent='center'>
                        {showToc ? (
                            <DocumentTableOfContents
                                outline={outline}
                                onPageClick={setPageNumber}
                                currentPage={currentPage}
                                onCloseClick={() => setShowToc(false)}
                            />
                        ) : null}
                        {showSearch ? (
                            <DocumentSearch
                                onPageClick={setPageNumber}
                                currentPage={currentPage}
                                partNumber={partNumber}
                                onCloseClick={() => setShowSearch(false)}
                            />
                        ) : null}
                        <DocumentPageView
                            hasLeftPage={currentPage > 0}
                            hasRightPage={nextPage <= pageCount}
                            currentPage={currentPage}
                            documentDimensions={documentDimensions}
                            partNumber={partNumber}
                            currentPageOffset={currentPageOffset}
                            nextPageOffset={nextPageOffset}
                            isNextPageInCurrentSection={isNextPageInCurrentSection}
                            currentSection={currentSection}
                            nextSection={nextSection}
                            setPageNumber={setPageNumber}
                            pageCount={pageCount}
                        />
                    </Stack>
                </Grid>
            </Grid>
            <DocumentViewMenu
                setPageNumber={setPageNumber}
                currentPage={currentPage}
                pageCount={pageCount}
                showToc={!!outline}
                onToggleToc={() => {
                    setShowToc(!showToc);
                    // if it was not show toc that means it's now show search so dimiss search so we can use the same space
                    if (!showToc) {
                        setShowSearch(false);
                    }
                }}
                onToggleSearch={() => {
                    setShowSearch(!showSearch);
                    // if it was not show search that means it's now show search so dimiss toc so we can use the same space
                    if (!showSearch) {
                        setShowToc(false);
                    }
                }}
                showingZoom={showZoom}

                onClickZoom={() => setShowZoom(!showZoom)}
                zoomRotation={zoomRotation}
                setZoomRotation={setZoomRotation}
                zoomScale={zoomScale}
                setZoomScale={setZoomScale}

                partNumber={partNumber}
                hasMultipleSections={sections?.length > 1}
                currentSection={currentSection}
                nextSection={nextSection}
                pageMappings={pages}
            />
            <ZoomDialog
                open={showZoom}
                onClose={() => setShowZoom(false)}
                partNumber={partNumber}
                currentSection={currentSection}
                currentPage={currentPage}
                currentPageOffset={currentPageOffset}
                zoomRotation={zoomRotation}
                zoomScale={zoomScale}
                isSinglePageMode={isSinglePageMode}
                sectionOneHasNextPage={isNextPageInCurrentSection && !isSinglePageMode}
                pageRatio={pageRatio}
                nextSection={nextSection}
                nextPageOffset={nextPageOffset}
            />
        </Box >
    );
}
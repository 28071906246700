import React from "react";
import FilterCollapseGroup from "./FilterCollapseGroup";
import { List } from "@mui/material";
import ListItemCheckbox from "./ListItemCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { changeFilter } from "../../../redux/libraryFiltersSlice";

export default function StateFilterGroup() {
    const dispatch = useDispatch();
    const {
        state
    } = useSelector((state) => state.libraryFilters);

    const litStates = useSelector((state) => state.permissions.litStates)

    if ((litStates || []).length <= 1) {
        return null;
    }

    return (
        <FilterCollapseGroup
            title='State'
        >
            <List component="div" disablePadding>
                {litStates
                    .map(item => (
                        <ListItemCheckbox
                            key={item.id}
                            id={`state-${item.id}`}
                            text={item.description}
                            isChecked={state == item.id}
                            onClick={() => dispatch(changeFilter({ fieldName: 'state', value: item.id }))}
                        />
                    ))}
            </List>
        </FilterCollapseGroup>
    );
}
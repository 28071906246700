import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dismissPrompt } from '../redux/notificationsSlice';

export default function PromptModal() {
    const dispatch = useDispatch();
    const prompt = useSelector((state) => state.notifications.prompt);

    if(!prompt) {
        return null;
    }

    const { title, message, onAccept, onCancel } = prompt || {};

    const handleAccept = () => {
        if (!!onAccept) {
            onAccept();
        }

        dispatch(dismissPrompt());
    }

    const handleCancel = () => {
        if (!!onCancel) {
            onCancel();
        }

        dispatch(dismissPrompt());
    }

    return (
        <Dialog
            open={!!prompt}
            onClose={handleCancel}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
        >
            <DialogTitle id="dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleAccept} autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}
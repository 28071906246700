import React from "react";
import { usePhoneMode, useIsSinglePage } from "../../../hooks";
import { HorizontalListItem } from "../../HorizontalList";
import { Button } from "@mui/material";

export default function DocumentViewMenuPageMarker({ currentPage, pageCount, hasCover, onShowJumpModal }) {
    const { isMobile, isLandscape } = usePhoneMode();
    const { isSinglePageMode } = useIsSinglePage();

    if (isMobile && !isLandscape && hasCover) {
        if (currentPage < 0) {
            return (
                <JumpToButton
                    onClick={onShowJumpModal}
                >
                    Cover
                </JumpToButton>
            );
        } else if (currentPage === 0) {
            return (
                <JumpToButton
                    onClick={onShowJumpModal}
                >
                    TOC
                </JumpToButton>
            );
        } else {
            return (
                <JumpToButton
                    onClick={onShowJumpModal}
                >
                    Page {currentPage}
                </JumpToButton>
            );
        }
    } else if (isMobile && !isLandscape && !hasCover) {
        return (
            <JumpToButton
                onClick={onShowJumpModal}
            >
                Page {currentPage}
            </JumpToButton>
        );
    }

    if (hasCover && currentPage === 0) {
        return (
            <JumpToButton
                onClick={onShowJumpModal}
            >
                TOC
            </JumpToButton>
        );
    }

    if (hasCover && currentPage < 0) {
        return (
            <JumpToButton
                onClick={onShowJumpModal}
            >
                Cover of {pageCount}
            </JumpToButton>
        );
    }

    if (!hasCover && currentPage == 0) {
        return (
            <JumpToButton
                onClick={onShowJumpModal}
            >
                Page {currentPage + 1} of {pageCount}
            </JumpToButton>
        );
    }

    return (
        <>
            {pageCount >= currentPage + 1 ? (
                <JumpToButton
                    onClick={onShowJumpModal}
                >
                    Page {isSinglePageMode ? currentPage : `${currentPage} - ${currentPage + 1}`} of {pageCount}
                </JumpToButton>
            ) : (
                <JumpToButton
                    onClick={onShowJumpModal}
                >
                    Page {currentPage} of {pageCount}
                </JumpToButton>
            )}
        </>
    );
}

function JumpToButton({ onClick, children }) {
    return (
        <HorizontalListItem>
            <Button
                style={{ textTransform: 'unset' }}
                onClick={onClick}
                title='Jump to Page'
            >
                {children}
            </Button>
        </HorizontalListItem>
    );
}
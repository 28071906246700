import React from 'react';
import { Fab } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import useWindowScrollTop from '../../hooks/useWindowScrollTop';
import useWindowDimensions from '../../hooks/useWindowDimensions';

export default function ScrollToTop() {
    const { scrollTop } = useWindowScrollTop();
    const { height } = useWindowDimensions();

    return (
        <Fab
            title='Back to Top'
            variant='extended'
            style={{
                position: 'fixed',
                bottom: 32,
                right: 32,
                display: scrollTop > height / 4 ? null : 'none'
            }}
            onClick={() => document.getElementById('scroll-viewer').scrollTo({ top: 0, behavior: 'smooth' })}
        >
            <ArrowUpwardIcon />
            Back To Top
        </Fab>
    );
}
import React, { useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPermissions } from '../../redux/permissionsSlice';
import { changeFilter } from '../../redux/libraryFiltersSlice';
import { useLanguageList } from '../../fetch/fetchClassificationItems';
import { useNotification } from '../../hooks';
import { apiHostUrl } from '../../helpers';
import { getAuthenticatedHeaders } from '../../fetch/fetchHeaders';

export default function ChangeDefaultLanguageDialog({ open, onClose }) {
    const { showError } = useNotification();
    const dispatch = useDispatch();
    const defaultLanguage = useSelector((state) => state.permissions.language)

    const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

    useMemo(() => {
        setSelectedLanguage(defaultLanguage);
    }, [defaultLanguage]);

    const { languages } = useLanguageList();

    const handleLanguageChange = (event) => {
        const newLanguage = event.target.value;
        setSelectedLanguage(newLanguage);
    }

    const handleOkClick = () => {
        // Update filter
        dispatch(changeFilter({ fieldName: 'language', value: selectedLanguage }));

        //Update server
        getAuthenticatedHeaders().then((headers) => {
            fetch(`${apiHostUrl}/user/language/${selectedLanguage}`,
                {
                    method: 'PUT',
                    headers
                })
                .then(() => {

                    // Refresh permissions which has the default language
                    dispatch(fetchPermissions());

                    // On Close
                    onClose();
                }, () => {
                    showError('Error changing language');
                });
        });
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>Change Default Language</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Choose your preferred language when translated manuals are available:
                </DialogContentText>
                <FormControl fullWidth style={{ marginTop: '1em' }}>
                    <InputLabel id='language-select-label'>Language</InputLabel>
                    <Select
                        labelId='language-select-label'
                        id='language-select-label'
                        value={selectedLanguage}
                        label="Language"
                        onChange={handleLanguageChange}
                        sx={{ width: '100%' }}
                    >
                        {languages.map(item => {
                            return (
                                <MenuItem key={item.id + item.nameLocal} value={item.code}>{item.nameLocal}</MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleOkClick}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}
import React from 'react';

export default function VimeoEmbed({ videoId, preferredWidth, preferredHeight }) {
    return (
        <iframe
            width={preferredWidth}
            height={preferredHeight}
            src={`https://player.vimeo.com/video/${videoId}`}
            title="Vimeo video player"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
        >
        </iframe>
    );
}
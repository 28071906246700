import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAuthenticatedHeaders } from '../fetch/fetchHeaders';

export const fetchPermissions = createAsyncThunk(
    'users/fetchByIdStatus',
    async (thunkAPI) => {
        const response = await getAuthenticatedHeaders().then((headers) => {
            return fetch('configuration/permissions', { headers })
                .then(response => response.json());
        })
        return response;
    }
)

export const permissionsSlice = createSlice({
    name: 'permissions',
    initialState: {
        isLoaded: false,
        accountNumber: '',
        businessGroups: [],
        litStates: [],
        isRecognized: false,
        language: '',
        canDownload: false
    },
    reducers: {
        startReload: (state) => {
            state.isLoaded = false
        }
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(fetchPermissions.fulfilled, (state, action) => {
            state.isLoaded = true;
            state.accountNumber = action.payload.accountNumber;
            state.businessGroups = action.payload.businessGroups;
            state.litStates = action.payload.litStates;
            state.isRecognized = action.payload.isRecognized;
            state.language = action.payload.language;
            state.canDownload = action.payload.canDownload;
        })
    },
})

// Action creators are generated for each case reducer function
export const { startReload } = permissionsSlice.actions

export default permissionsSlice.reducer
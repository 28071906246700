import React from "react";
import FilterCollapseGroup from "./FilterCollapseGroup";
import { List } from "@mui/material";
import ListItemCheckbox from "./ListItemCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { changeFilter } from "../../../redux/libraryFiltersSlice";

export default function BusinessGroupFilterGroup() {
    const dispatch = useDispatch();
    const {
        group: businessGroup
    } = useSelector((state) => state.libraryFilters);

    const businessGroups = useSelector((state) => state.permissions.businessGroups)

    const isChecked = (id) => {
        if (id == 0 && (businessGroup.length == 0 || businessGroup.length === businessGroups.length - 1)) {
            return true;
        } else {
            return businessGroup.indexOf(id) >= 0;
        }
    }

    const isIndeterminate = (id) => {
        if (id != 0) {
            return false;
        } else {
            return businessGroup.length !== 0 && businessGroup.length !== businessGroups.length - 1;
        }
    }

    const handleItemClick = (id) => {
        if (id != 0) {
            if (businessGroup.indexOf(id) < 0) {
                dispatch(changeFilter({ fieldName: 'group', value: [...businessGroup, id].sort((a, b) => { return a - b; }) }));
            } else {
                dispatch(changeFilter({ fieldName: 'group', value: businessGroup.filter(it => it != id).sort((a, b) => { return a - b; }) }));
            }
        }
        else {
            dispatch(changeFilter({ fieldName: 'group', value: [] }));
        }
    }

    return (
        <FilterCollapseGroup
            title='Group'
        >
            <List component="div" disablePadding>
                {businessGroups
                    .map(item => (
                        <ListItemCheckbox
                            key={item.id}
                            id={`business-group-${item.id}`}
                            text={item.name}
                            isChecked={isChecked(item.id)}
                            isIndeterminate={isIndeterminate(item.id)}
                            onClick={() => { handleItemClick(item.id) }}
                        />
                    ))}
            </List>
        </FilterCollapseGroup>
    );
}
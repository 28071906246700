import React from 'react';
import ContentContainer from './components/servicePortal/contentContainer';
import PageTitle from './components/servicePortal/pageTitle';
import { Box, Grid } from '@mui/material';

export default function ServicePortalErrorPage({ status }) {
    return (
        <ContentContainer>
            <PageTitle title={status == 404 ? 'Not Found' : 'Unexpected Error'} />
            <Box component="main" sx={{ p: 3, width: '100%', marginTop: '-2em' }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        {status == 404 ? (
                            <p>This page does not seem to exist, <a href="/">click here</a> to return to the home page.</p>
                        ) : (
                            <p>Sorry, an unexpected error has occurred.</p>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </ContentContainer>
    );
}
import React from 'react';
import { ListItemText } from '@mui/material';
import Highlighter from 'react-highlight-words';
import SelectedListItemText from '../SelectedListItemText';

export default function SearchItemText({ isPageShowing, title, query, pageNumber }) {
    const contents = (
        <>
            <span style={{fontWeight: 'bold'}}>Page {pageNumber}</span>
            <br />
            <Highlighter
                // highlightClassName="YourHighlightClass" // not sure if we'll need this
                searchWords={[query]}
                autoEscape={true}
                textToHighlight={title}
            />
        </>
    );

    return isPageShowing ? <SelectedListItemText>{contents}</SelectedListItemText> : <ListItemText>{contents}</ListItemText>;
}
import styled from '@emotion/styled';

const TypeTitle = styled('div')(({ theme, documentTypeId, detailsMode, isNew, fontSize }) => {
    let color = theme.palette.typeTitle.default;
    let contrastColor = theme.palette.typeTitle.defaultContrast;
    if (documentTypeId == 1 || documentTypeId == 5 || documentTypeId == 6) {
        color = theme.palette.typeTitle.serviceManual;
        contrastColor = theme.palette.typeTitle.serviceManualContrast;
    }
    if (documentTypeId == 2 || documentTypeId == 9) {
        color = theme.palette.typeTitle.bulletin;
        contrastColor = theme.palette.typeTitle.bulletinContrast;
    }
    if (documentTypeId == 3) {
        color = theme.palette.typeTitle.installationManual;
        contrastColor = theme.palette.typeTitle.installationManualContrast;
    }
    if (documentTypeId == 4) {
        color = theme.palette.typeTitle.operationManual;
        contrastColor = theme.palette.typeTitle.operationManualContrast;
    }

    return ({
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        borderRadius: detailsMode ? theme.shape.borderRadius : null,
        maxWidth: detailsMode ? 'max-content' : null,
        float: detailsMode ? 'right' : null,
        backgroundColor: color,
        color: contrastColor,
        marginLeft: 0,
        width: '100%',
        padding: theme.spacing(0.5),
        textAlign: 'center',
        fontWeight: 450,
        marginTop: isNew ? '-2em' : null,
        fontSize
    });
});

export default TypeTitle;

const VideoTypeTitle = styled('div')(({ theme, bottom, detailsMode }) => {
    let color = '#000';
    let contrastColor = theme.palette.primary.contrastText;

    return ({
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        backgroundColor: color,
        color: contrastColor,
        width: '100%',
        padding: theme.spacing(0.5),
        textAlign: 'center',
        fontWeight: 450,
        position: 'absolute',
        left: 0,
        right: 0,
        bottom,
        verticalAlign: 'middle'
    });
});

export {
    VideoTypeTitle
}
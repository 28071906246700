import useSWR, { preload } from 'swr';
import { getAuthenticatedHeaders } from './fetchHeaders';

export const preloadPageImage = (partNumber, pageIndex) => {
    const url = getPagePath(partNumber, pageIndex);
    if (!url) {
        return;
    }
    preload(url, imageFetcher);
}

export const usePageImage = (partNumber, pageIndex) => {
    const { data, error, isValidating, isLoading, mutate } = useSWR(
        getPagePath(partNumber, pageIndex),
        imageFetcher,
        swrConfig);

    return { image: data, error, isValidating, isLoading, mutate };
};

const getPagePath = (partNumber, pageIndex) => {
    if (!partNumber || pageIndex < 0 || pageIndex == undefined || Number.isNaN(pageIndex)) {
        return null;
    }

    return `/document/${partNumber}/page/${pageIndex}.jpg`;
}

export const useThumbImage = (partNumber) => {
    const { data, error, isValidating, isLoading, mutate } = useSWR(
        getThumbPath(partNumber),
        imageFetcher,
        swrConfig);

    return { image: data, error, isValidating, isLoading, mutate };
};

const getThumbPath = (partNumber) => {
    if (!partNumber) {
        return null;
    }

    return `/document/${partNumber}/thumbnail.jpg`;
}

export const useHalfThumbImage = (partNumber) => {
    const { data, error, isValidating, isLoading, mutate } = useSWR(
        getHalfThumbPath(partNumber),
        imageFetcher,
        swrConfig);

    return { image: data, error, isValidating, isLoading, mutate };
};

const getHalfThumbPath = (partNumber) => {
    if (!partNumber) {
        return null;
    }

    return `/document/${partNumber}/thumbnail_half.jpg`;
}

export const useQuarterThumbImage = (partNumber) => {
    const { data, error, isValidating, isLoading, mutate } = useSWR(
        getQuarterThumbPath(partNumber),
        imageFetcher,
        swrConfig);

    return { image: data, error, isValidating, isLoading, mutate };
};

const getQuarterThumbPath = (partNumber) => {
    if (!partNumber) {
        return null;
    }

    return `/document/${partNumber}/thumbnail_quarter.jpg`;
}

const imageFetcher = (url) => {
    return getAuthenticatedHeaders().then((headers) => {
        return fetch(url, { headers })
            .then(response => response.blob())
            .then(blob => URL.createObjectURL(blob))
    });
}

const swrConfig = {
    revalidateOnFocus: false,
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Never retry on 4XX error.
        if (error.status >= 400 && error.status < 500) return;

        //   // Never retry for a specific key.
        //   if (key === '/api/user') return

        // Only retry up to 1 times.
        if (retryCount > 1) return;

        // Retry after 1 seconds.
        setTimeout(() => { revalidate({ retryCount }); }, 1000)
    }
};
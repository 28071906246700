import styled from "@emotion/styled";
import { Button, IconButton } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import usePhoneMode from "../../../hooks/usePhoneMode";

const FilterButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.contrastText
}));

const ClearButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    padding: 0
}));

const FilteredSearchToggleButton = styled('div')(({ theme }) => ({
    borderRadius: '4px',
    // height: '24px',
    lineHeight: 1.75,
    fontWeight: 500,
    alignItems: 'center',
    display: 'inline-flex',
    padding: '0 8px',
    margin: '6px 16px',
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
}));

export default function FilterClearButton({ title, onClearClick, onToggleOpenClick, displayValue }) {
    const { isMobile } = usePhoneMode();

    if (!displayValue || isMobile) {
        return null;
    }

    return (
        <>
            |&nbsp;
            <ClearButton
                onClick={onClearClick}
                title={title}
            >
                <CancelIcon />
            </ClearButton>
            <FilterButton
                onClick={onToggleOpenClick}
            >
                {displayValue}
            </FilterButton>
        </>
    );
}

export {
    FilterButton,
    ClearButton,
    FilteredSearchToggleButton
}
import React from 'react';
import { Box } from '@mui/material';

import PageSpinner from './PageSpinner';

export default function DocumentLoading({documentHeight}) {
    return (
        <Box component="main" sx={{ p: 0, overflow: 'hidden', maxHeight: '100%', width: '100%' }}>
            <PageSpinner documentHeight={documentHeight} />
        </Box>
    );
}
import { parameterHelper } from './parameterHelper';
import { UserRoute, UserPermissionRoute } from './routeHelpers';
import { storageHelper } from './storageHelper';

const apiHostUrl = window.location.origin;

const listifyKeys = (object) => {
    return Object.keys(object).map(key => object[key]).join('-')
}

export {
    parameterHelper,
    apiHostUrl,
    listifyKeys,
    UserRoute,
    UserPermissionRoute,
    storageHelper
}
import { useState } from "react";

export const useMergeState = (initialState) => {
    const [state, setStateInner] = useState(initialState);
    const setState = (changes) => {
        setStateInner({ ...state, ...changes });
    }

    return [state, setState];
}

export const useModelState = (model) => {
    const [loaded, setLoadedState] = useState('');
    const [stateModel, setModelState] = useMergeState({});

    if (loaded !== JSON.stringify(model)) {
        setLoadedState(JSON.stringify(model));
        setModelState(model);
    }

    const handleValueChanged = (fieldName, value) => {
        const newModel = { ...stateModel };
        newModel[fieldName] = value;
        setModelState(newModel);
    }

    return [stateModel, handleValueChanged, setModelState];
}
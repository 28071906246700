import { createSlice } from '@reduxjs/toolkit'

export const defaultFiltersState = {
    isExpandMode: false,
    isSinglePageMode: false
}

export const documentViewSlice = createSlice({
    name: 'documentView',
    initialState: {
        ...defaultFiltersState
    },
    reducers: {
        setSinglePageMode: (state, action) => {
            state.isSinglePageMode = action.payload;
        },
        setExpandMode: (state, action) => {
            state.isExpandMode = action.payload;
        }
    }
})

// Action creators are generated for each case reducer function
export const { setSinglePageMode, setExpandMode } = documentViewSlice.actions

export default documentViewSlice.reducer
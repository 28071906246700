import { useEffect } from "react";
import { parameterHelper } from "../helpers/parameterHelper";
import { useDispatch } from "react-redux";
import { changeFilter } from "../redux/libraryFiltersSlice";
import { useLocation } from "react-router-dom";

export default function useCheckForPassedInFamilyFilter() {
    const dispatch = useDispatch();
    const { pathname, search } = useLocation();
    const params = new URLSearchParams(search);
    const { familyNameId: passedInFamilyNameId } = parameterHelper.getUrlParameters({ familyNameId: null });

    useEffect(() => {
        if (!!passedInFamilyNameId) {
            dispatch(changeFilter({ fieldName: 'families', value: passedInFamilyNameId.split(',').map(it => +it) }));

            params.delete('familyNameId');
            setTimeout(() => {
                window.location.replace(params.size >= 1 ? `${pathname}?${params.toString()}` : pathname);
            }, 250);
        }
    }, [passedInFamilyNameId]);
}
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ScaleText from "react-scale-text";
import { VideoTypeTitle } from './TypeTitle';

import './DocumentListItemIcon.css';
import { useVideoMetadata } from '../../fetch/fetchMetadata';

export default function VideoThumbnail({ videoId, title, showTitle, minHeight }) {
    const [forceReload, setForceReload] = useState(false);
    const { metadata } = useVideoMetadata(videoId);
    const { isYouTube, posterUrl } = metadata;

    useEffect(() => {
        if (forceReload) {
            setForceReload(false);
        }
    }, [forceReload]);

    const [containerDimensions, setContainerDimensions] = useState({ width: 0, height: 0 });

    const containerRef = useCallback(node => {
        if (!node) return;
        const resizeObserver = new ResizeObserver(() => {
            setContainerDimensions({
                height: node.getBoundingClientRect().height,
                width: node.getBoundingClientRect().width
            });
            setForceReload(true);
        });
        resizeObserver.observe(node);
    }, []);

    const imgRef = useRef(null)
    const imageDimensions = {
        width: imgRef?.current?.clientWidth ?? 0,
        height: imgRef?.current?.clientHeight
    };

    let imageTop = 0;
    let playTop = 0;
    let playWidth = 0;
    let playLeft = 0;

    if (containerDimensions.height > 0 && imageDimensions.height > 0) {
        imageTop = (containerDimensions.height - imageDimensions.height) / 2;

        playWidth = Math.min(imageDimensions.height, imageDimensions.width) * .7;
        playTop = (containerDimensions.height - playWidth) / 2;
        playLeft = (imageDimensions.width - playWidth) / 2;
    }

    return (
        <div ref={containerRef} style={{ backgroundColor: showTitle ? '#000' : null, height: '100%', minHeight }}>
            <div
                style={{ height: '100%', width: '100%', display: 'inline-block' }}
            >
                <div
                    style={{ height: '100%', width: '100%', position: 'relative' }}
                >
                    {!forceReload && showTitle ? (
                        <VideoTypeTitle
                            bottom={imageTop + imageDimensions.height}
                        >
                            <ScaleText>
                                {title}
                            </ScaleText>
                        </VideoTypeTitle>
                    ) : null}
                    {isYouTube ? (
                        <img
                            ref={imgRef}
                            alt={title}
                            title={title}
                            src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
                            style={{
                                maxWidth: containerDimensions.width,
                                maxHeight: containerDimensions.height,
                                position: 'absolute',
                                top: imageTop
                            }}
                        />
                    ) : (
                        <img
                            ref={imgRef}
                            alt={title}
                            title={title}
                            src={posterUrl}
                            style={{
                                maxWidth: containerDimensions.width,
                                maxHeight: containerDimensions.height,
                                position: 'absolute',
                                top: imageTop
                            }}
                        />
                    )}
                    <img
                        alt=''
                        src='play-button.svg'
                        style={{
                            width: playWidth,
                            height: playWidth,
                            position: 'absolute',
                            top: playTop,
                            left: playLeft
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
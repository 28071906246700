import React from "react";
import ContentContainer from "./contentContainer";
import { Box, Grid } from "@mui/material";
import PageTitle from "./pageTitle";
import { useReleaseNotes } from "../../fetch/fetchReleaseNotes";
import { apiHostUrl } from "../../helpers";
import './g3ReleaseNotes.css';
import Spinner from "../spinner/Spinner";

export default function G3ReleaseNotes() {
    const { data: files, isLoading } = useReleaseNotes();

    return (
        <ContentContainer>
            <PageTitle title='Release Notes' />

            <Box component="main" sx={{ p: 3, width: '100%' }}>
                <Grid container className="notes-list">
                    {!isLoading ? (
                        <Grid item xs={12}>
                            <h4>
                                <span>Release Notes</span>
                            </h4>
                            {files.map(item => {
                                return (
                                    <div key={item}>
                                        <a download href={`${apiHostUrl}/api/v1/file/notes/${item}`}>{item}</a>
                                    </div>
                                );
                            })}
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <Spinner />
                        </Grid>
                    )}
                </Grid>
            </Box>
        </ContentContainer>
    );
}
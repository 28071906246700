import useSWR from 'swr';
import { httpResponseToJSON } from '../helpers/fetchHelpers';
import { useDebounce } from 'use-debounce';

export const useIsLicenseKeyValidForDownload = (accountNumber, licenseKey, dealerExists) => {
    const [debouncedLicenseKey, callback] = useDebounce(getValidateLicenseKeyG3(accountNumber, licenseKey, dealerExists), 1000);
    const { data, error, isValidating, isLoading } = useSWR(
        debouncedLicenseKey,
        fetcher,
        swrConfig);

    return { data, error, isValidating, isLoading: isLoading || callback.isPending() };
}

const getValidateLicenseKeyG3 = (accountNumber, licenseKey, dealerExists) => {
    if (!accountNumber || accountNumber.length < 4 || !licenseKey || licenseKey.length != 29 || !dealerExists) {
        return null;
    }

    return `/api/v1/account/ValidateG3LicenseKey/${licenseKey}/${accountNumber}`;
}

export const useIsLicenseKeyValidForDieselDownload = (accountNumber, licenseKey, dealerExists) => {
    const [debouncedLicenseKey, callback] = useDebounce(getValidateLicenseKeyDiesel(accountNumber, licenseKey, dealerExists), 1000);
    const { data, error, isValidating, isLoading } = useSWR(
        debouncedLicenseKey,
        fetcher,
        swrConfig);

    return { data, error, isValidating, isLoading: isLoading || callback.isPending() };
}

const getValidateLicenseKeyDiesel = (accountNumber, licenseKey, dealerExists) => {
    if (!accountNumber || accountNumber.length < 4 || !licenseKey || licenseKey.length != 29 || !dealerExists) {
        return null;
    }

    return `/api/v1/account/ValidateDieselLicenseKey/${licenseKey}/${accountNumber}`;
}

const fetcher = (url) => {
    return fetch(url)
        .then(response => httpResponseToJSON(response, 'Error loading data'/*, showError*/));
}

const swrConfig = {
    revalidateOnFocus: false,
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Never retry on 4XX error.
        if (error.status >= 400 && error.status < 500) return;

        //   // Never retry for a specific key.
        //   if (key === '/api/user') return

        // Only retry up to 1 times.
        if (retryCount > 1) return;

        // Retry after 1 seconds.
        setTimeout(() => { revalidate({ retryCount }); }, 1000)
    }
};
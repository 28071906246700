import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist';
import storageSession from 'reduxjs-toolkit-persist/lib/storage/session'
import permissionsReducer from './permissionsSlice';
import libraryFiltersReducer from './libraryFiltersSlice';
import thunk from 'redux-thunk';
import documentViewSlice from './documentViewSlice';
import notificationsReducer from './notificationsSlice';

const persistConfig = {
  key: 'root',
  storage: storageSession,
  blacklist: ['isExpandMode']
}

export const store = configureStore({
  reducer: {
    permissions: permissionsReducer,
    libraryFilters: persistReducer(persistConfig, libraryFiltersReducer),
    documentView: persistReducer(persistConfig, documentViewSlice),
    notifications: notificationsReducer 
  },
  middleware: [thunk],
})

export const persistor = persistStore(store)
import React, { Component } from 'react';
import NavMenu from './nav/NavMenu';
import { Box, Container, Grid } from '@mui/material';
import styled from '@emotion/styled';
import ReAuthDialog from './ReAuthDialog';
import PromptModal from './promptModal';
import { useLocation } from 'react-router-dom';
import Footer from './Footer';
import { useTheme } from '@emotion/react';
import ErrorModal from './errorModal';

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

export default function Layout({ children }) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const isInLiteratureView = pathname.indexOf('/literature') >= 0;

  return (
    <>
      <NavMenu />
      <Offset />
      <Box sx={{ display: 'flex', height: `calc(100vh - ${theme.appBar.height}px)` }}>
        {children}
      </Box>
      <ReAuthDialog />
      <PromptModal />
      <ErrorModal />
      {!isInLiteratureView ? <Footer /> : null}
    </>
  );
}